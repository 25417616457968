<template>
  <div class="form-container">
    <div class="form-container__field-container form-container__header">
      Password Reset
    </div>
    <div class="form-container__field-container">
      <div v-for="(error, index) of validator.form.username.$errors" :key="index">
        <strong>{{ error.$message }}</strong>
      </div>
      <label for="username">Email or Phone Number</label>
      <input id="username" maxlength="100" type="text" v-model="validator.form.username.$model"/>
    </div>
    <div class="form-container__field-container">
      <div v-for="(error, index) of validator.form.pin.$errors" :key="index">
        <strong>{{ error.$message }}</strong>
      </div>
      <label for="pin">Verification Code</label>
      <input id="pin" maxlength="100" type="text" v-model="validator.form.pin.$model"/>
    </div>
    <div class="form-container__field-container">
      <div v-for="(error, index) of validator.form.password.$errors" :key="index">
        <strong>{{ error.$message }}</strong>
      </div>
      <label for="password">Password</label>
      <input id="password" maxlength="100" type="password" v-model="validator.form.password.$model"/>
    </div>
    <div class="form-container__field-container">
      <div v-for="(error, index) of validator.passwordConfirm.$errors" :key="index">
        <strong>{{ error.$message }}</strong>
      </div>
      <label for="passwordConfirm">Password Confirm</label>
      <input id="passwordConfirm" maxlength="100" type="password" v-model="validator.passwordConfirm.$model"/>
    </div>
    <div class="form-container__field-container">
      <button id="submitBtn" class="btn-primary" @click="submitForm" :disabled="loading">
        <font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
        <span v-if="!loading">Reset</span>
      </button>
    </div>
    <the-footer class="form-container__field-container"/>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import Login from '@/api/Login';
import TheFooter from "@/components/SingleTime/TheFooter";
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useNotificationStore } from '@dafcoe/vue-notification';
import { ErrorAlerts } from '@/constants/ErrorAlerts';
import { required, minLength, helpers, sameAs } from '@vuelidate/validators';

const hasLowerCase = helpers.regex(/[a-z]/);
const hasUpperCase = helpers.regex(/[A-Z]/);
const hasNumber = helpers.regex(/\d/);

export default {
  name: "ResetVerify",
  components: {
    TheFooter
  },
  setup() {
    const store = useStore();
    const { setNotification } = useNotificationStore();
    const username = computed(() => store.getters['user/getUsername']);

    return {
      username,
      setNotification,
      validator: useVuelidate({ $lazy: true })
    }

  },
  data() {
    return {
      form: {
        username: this.username,
        pin: '',
        password: ''
      },
      passwordConfirm: '',
      loading: false,
    }
  },
  methods: {
    async submitForm() {
      try {
        this.loading = true;
        await Login.resetCustomerPassword(this.form);
        await this.$router.push({ path: '/login'});
      } catch(error) {
        this.loading = false;
        this.setNotification({
          ...ErrorAlerts,
          message: error.response.data.errorMessage
        });
      }
    }
  },
  validations() {
    return {
      form: {
        username: {
          required: helpers.withMessage("Email or Phone Number is required", required),
        },
        pin: {
          required: helpers.withMessage('Verification code required', required)
        },
        password: {
          required: helpers.withMessage("Password is required", required),
          minLength: helpers.withMessage("Password must have more than 8 characters", minLength(8)),
          hasLowerCase: helpers.withMessage("Password must have a lowercase letter", hasLowerCase),
          hasUpperCase: helpers.withMessage("Password must have an uppercase letter", hasUpperCase),
          hasNumber: helpers.withMessage("Password must have a number", hasNumber)
        },
      },
      passwordConfirm: {
        required: helpers.withMessage("Password Confirmation is required", required),
        sameAs: helpers.withMessage("Passwords must match", sameAs(this.form.password))
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: 45px min-content min-content min-content min-content 90px 100px;
  grid-template-areas:
    "header"
    "username"
    "pin"
    "password"
    "passwordConfirm"
    "submitBtn"
    "footer";
  justify-content: center;
  padding: 36px 36px 10px 36px;
  color: $black;

  &__header {
    text-align: center;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 600;
    font-size: 26px;
  }

  &__field-container:first-of-type {
    grid-area: header;
  }

  &__field-container:nth-of-type(2) {
    grid-area: username;
  }

  &__field-container:nth-of-type(3) {
    grid-area: pin;
  }

  &__field-container:nth-of-type(4) {
    grid-area: password;
  }

  &__field-container:nth-of-type(5) {
    grid-area: passwordConfirm;
  }

  &__field-container:nth-of-type(6) {
    grid-area: submitBtn;
  }

  &__field-container:nth-of-type(7) {
    grid-area: footer;
  }
}

@media(max-width: $media-large) {
  .form-container {
    max-width: 350px;
  }
}
</style>
