import * as getters from './getters';

const state = {
  nav: [
    {
      step: 1,
      path: '/contract'
    },
    {
      step: 2,
      path: '/paymentBreakdown'
    },
    {
      step: 3,
      path: '/payment'
    }
  ]
};

export default {
  namespaced: true,
  state,
  getters,
};
