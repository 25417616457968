import { createApp } from 'vue'
import VueLogger from 'vuejs3-logger';
import App from './App.vue';
import router from './router/Routes';
import store from './store';
import { fontAwesome, idle } from './global';
import VueNotificationList from '@dafcoe/vue-notification'
import VueHtmlToPaper from "@/global/VueHtmlToPaper";

import '@fortawesome/fontawesome-svg-core/styles.css';
import '@fortawesome/fontawesome-svg-core/index';

const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

const app = createApp(App);
app.use(store);
app.use(router);
app.use(fontAwesome);
app.use(idle);
app.use(VueNotificationList);
app.use(VueHtmlToPaper);
app.mount('#app');
app.use(VueLogger, options);
