<template>
  <div class="receipt-container">
    <div class="receipt-container__button-container">
      <button class="btn btn-primary" @click="print">Print</button>
    </div>
    <div class="receipt-container__button-container">
      <button class="btn btn-primary" @click="startAgain">New Payment</button>
    </div>
    <div id="receipts">
      <div v-for="(receipt, i) in receipts" :key="i">
        <div v-html="receipt.receiptData.theReceipt"></div>
      </div>
    </div>
    <div class="receipt-container__button-container">
      <button class="btn btn-primary" @click="close">Finish</button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: "Receipt",
  setup() {
    const store = useStore();
    const locationId = computed(() => store.getters['location/getLocationId']);
    const receiptIds = computed(() => store.getters['payment/getReceiptIds']);
    const receipts = computed(() => store.getters['payment/getReceipts']);
    const resetAll = () => store.dispatch('clearAll');

    return {
      locationId,
      receiptIds,
      receipts,
      resetAll
    }
  },
  methods: {
    print() {
      this.$htmlToPaper('receipts');
    },
    startAgain() {
      const locationId = this.locationId;
      localStorage.clear();
      this.resetAll();
      this.$router.push({path: '/', query: { locationId }});
    },
    close() {
      const locationId = this.locationId;
      localStorage.clear();
      this.resetAll();
      if(window.opener === null) {
        this.$router.push({path: '/', query: { locationId }});
      } else {
        window.close();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.receipt-container {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  grid-template-columns: 300px 300px;
  grid-template-rows: 70px auto 70px;
  grid-template-areas: "print anotherPayment" "receipts receipts" "finish finish";
  justify-content: center;

  &__button-container {
    position: relative;
  }

  &__button-container:first-of-type {
    grid-area: print;
  }

  &__button-container:nth-of-type(2) {
    grid-area: anotherPayment;
  }

  &__button-container:nth-of-type(4) {
    grid-area: finish;
  }
}

#receipts {
  grid-area: receipts;
}

@media(max-width: $media-large) {
  .receipt-container {
    grid-template-columns: 175px 175px;

    &__button-container:nth-of-type(2) button.btn-primary {
      font-size: 21px;
    }
  }


}
</style>
