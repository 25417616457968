import Payment from "@/api/Payment";
import Receipt from "@/api/Receipt";

export const updatePaymentInfo = ({ commit }, payment) => {
  commit('updatePayment', payment);
};

export const updateCardInfo = ({ commit }, card) => {
  commit('updateCardInfo', card);
};

export const updateMonthsAhead = ({ commit }, monthsAhead) => {
  commit('updateMonthsAhead', monthsAhead);
}

export const updateMonthsAheadDisplay = ({ commit }, monthsAheadDisplay) => {
  commit('updateMonthsAheadDisplay', monthsAheadDisplay);
};

export const updateTotal = ({ commit }, total) => {
  commit('updateTotal', total);
}

export const getPaymentMethods = async ({ commit }, contractIds) => {
  const data = await Payment.getPaymentMethods(contractIds);
  commit('updateSavedPaymentMethods', data.data);
};

export const savePaymentMethod = async (_, savePaymentMethodDto) => {
  await Payment.savePaymentMethods(savePaymentMethodDto);
};

export const submitPayment = async ({ commit }, payment) => {
  const data = await Payment.submitPayment(payment);
  commit('updateReceiptIds', data.data.receiptID);
};

export const fetchReceipts = async ({ commit }, receipts) => {
  const data = await Receipt.getReceipts(receipts);
  commit('updateReceipts', data.data.map(response => response.receipt));
};

export const reset = ({ commit }) => {
  commit('reset');
};

export const removeAutoPay = async (_, contractUnitID) => {
  await Payment.removeAutoPay(contractUnitID);
};

export const updateAutoPay = async (_,  autopayDto) => {
  await Payment.updateAutoPay(autopayDto);
};

