import Axios from "axios";
import {API_ROOT} from "@/constants/ApiRoot";

const RECEIPT_ENDPOINT = `${API_ROOT}/receipt`

export default {
  async getReceipt(receiptId) {
    return await Axios.get(`${RECEIPT_ENDPOINT}/${receiptId}`);
  },
  async getReceipts(receipts) {
    const stringReceipts = receipts.reduce((f, s) => `${f},${s}`);
    return await Axios.get(`${RECEIPT_ENDPOINT}`, { params: { receipts: stringReceipts } });
  }
}
