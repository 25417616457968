export const getSelectedContractUnits = state => {
  return state.selectedContractUnits;
}

export const getSelectedContractUnitsFlat = state => {
  return Object.values(state.selectedContractUnits).flat();
}

export const getContracts = state => {
  return state.contracts;
}

export const getContractsForPayment = state => {
  const contractNumbers = Object.keys(state.selectedContractUnits);
  const contractUnitNumbers = Object.values(state.selectedContractUnits).flat();

  let contracts = Object.values(JSON.parse(JSON.stringify(state.contracts))) // the only way to deep copy an object
    .filter(contract => contractNumbers.includes(contract.contractNumber));

  contracts.forEach(contract => contract.contractUnits =
    contract.contractUnits.filter(contractUnit => contractUnitNumbers.includes(contractUnit.unitNumber)));

  return contracts;
}

export const getTotalCost = (state, getters, rootState) => {
  const contracts = getContractsForPayment(state);
  return contracts.map(contract =>
    contract.contractUnits.map(unit =>
      unit.unitPaymentBreakdowns[rootState.payment.monthsAhead].breakdownBalance)
      .reduce((a, b) => a + b, 0))
    .reduce((a, b) => a + b, 0);
}

// This is ass, but here we are
export const getLongestUnitPayListWithoutZeros = state => {
  const contractsToPay = getContractsForPayment(state);
  return contractsToPay.map(contract =>
    contract.contractUnits.map(unit =>
      unit.unitPaymentBreakdowns).flat()).reduce((leastZeros, current) =>
    leastZeros.filter(breakdown =>
      breakdown.balanceDue === 0).length <
    current.filter(breakdown =>
      breakdown.balanceDue === 0).length ?
      leastZeros : current);
}
