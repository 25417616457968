import Location from "@/api/Location";

export const fetchLocation = async ({ commit }, locationId) => {
  const location = await Location.getLocation(locationId);
  commit('setLocation', location.data);
}

export const fetchLocationByEntity = async ({ commit }, entityId) => {
  const data = await Location.getLocationByEntityAsync(entityId);
  commit('setLocation', data.data.location);
}

export const updateLocationId = ({ commit }, locationId) => {
  commit('updateLocationId', locationId);
}

export const reset = ({commit}) => {
  commit('reset');
}
