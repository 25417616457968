<template>
  <router-view v-slot="{ Component, route }">
    <teleport to="#modal-area">
      <modal @close-modal="closeModal" :disabled="shouldClose" v-if="!shouldClose" :show-modal="shouldOpen">
        <template v-slot:body>
          For your security, your session has expired. Click
          <a :href="$router.resolve(`/?locationId=${locationId}`).href" @click="closeModal">here</a>
          to try again.
        </template>
      </modal>
    </teleport>
    <v-idle @idle="handleIdle" :duration="900" />
    <the-header />
    <vue-notification-list position="top-right"></vue-notification-list>
    <transition :name="route.meta.transition || 'fade'" :mode="route.meta.mode || 'out-in'">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import '@dafcoe/vue-notification/dist/vue-notification.css';
import TheHeader from '@/components/SingleTime/TheHeader';
import TheFooter from "@/components/SingleTime/TheFooter";
import Modal from "@/components/Modal/Modal";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    Modal
  },
  setup() {
    const store = useStore();
    const locationId = computed(() => store.getters['location/getLocationId']);
    const clearAll = () => store.dispatch('clearAll');

    return {
      locationId,
      clearAll
    }
  },
  data() {
    return {
      shouldOpen: false,
      shouldClose: false
    }
  },
  methods: {
    handleIdle() {
      if (!this.shouldOpen) {
        this.clearAll();
        localStorage.clear();
        this.$router.push({ path: '/', query: { locationId: this.locationId } });
      }
      this.shouldOpen = true;
      this.shouldClose = false;
    },
    closeModal() {
      this.shouldClose = true;
      this.shouldOpen = false;
    }
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  background-color: $body-background;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-wrap: wrap;
  font-family: "Roboto";
}

#app {
  width: 100%;
  margin: 115px 200px 0 200px;
}

label {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: $black;
  padding-bottom: 5px;
}

input {
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border: 1px solid $light-grey;
}

input:focus-visible {
    outline: 1px solid $dark-grey;
    border-radius: 0;
}

.form-container__field-container input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.form-container__field-container {
  position: relative;
}
.verification__field-container {
  position: relative;
}

// fancy button css
@mixin poscenter($m: 50%, $n: $m, $tf1: null, $tf2: null) {
  position: absolute;
  left: $m;
  top: $n;
  transform: $tf1 translate(-$m, -$n) $tf2;
}

button.btn-primary {
  @include poscenter();
  color: $white;
  background: $primary-color;
  border: 2px solid $primary-color;
  text-decoration: none;
  font: normal normal 500 22px/25px "Roboto";
  display: inline-block;
  padding: 20px 20px;
  width: 100%;

  &:hover {
    cursor: pointer;
    border: 2px solid $primary-color;
    background-color: #0DB952;
  }
}

button.btn-secondary {
  @include poscenter();
  color: $primary-color;
  background: $white;
  border: 2px solid $primary-color;
  text-decoration: none;
  font: normal normal 500 22px/25px "Roboto";
  display: inline-block;
  padding: 20px 20px;
  width: 100%;

  &:hover {
    cursor: pointer;
    color: $white;
    border: 2px solid $primary-color;
    background-color: $primary-color;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}

.v-idle {
  // display this to see idle timer
  display: none;
}

strong {
  color: $error;
}

@media(max-width: $media-large) {
  #app {
    width: auto;
    margin: 115px 0 0 0;
  }
}
</style>
