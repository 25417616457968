export const toCurrency = (value) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
}

export const isPrepaid = (dueDate) => {
  const date = new Date(dueDate);
  date.setMonth(date.getMonth() - 1);
  const currentDate = new Date();
  return date.getMonth() > currentDate.getMonth();
}

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export const hasEmptyValues = (obj) => {
  return Object.values(obj).filter((v) => !v).length > 0;
}

export const isEmail = (emailAddress) =>
{
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return regexEmail.test(emailAddress);
}
