<template>
  <div class="payment-summary-container">
    <div class="breakdown-container">
      <div class="grid-row breakdown-container__title">
        Payment Summary
      </div>
      <div class="grid-row">
        <step-nav :active="2" />
      </div>
      <div class="grid-row breakdown-container__contract">
        <contract-breakdown v-for="(contract) in contractsToPay"
                            :months-ahead="monthsAhead"
                            :contract="contract"
                            :key="contract.contractId" />
      </div>
    </div>
    <div class="total-container">
      <div class="total-container__months-selector">
        <span class="total-container__prepay-label">Prepay Rent Through: </span>
        <multi-select @input="onSelect" :options="monthsSelectorData" :disabled="disabled" default="Prepay" />
      </div>
      <div class="total-container__total">
        <div class="total-container__label">Total Balance Due:</div>
        <div class="total-container__value">{{ formatCurrency(aggregateTotal) }}</div>
      </div>
      <div class="total-container__button">
        <button id="submitBtn" class="btn btn-primary" @click="submitForm">Continue to Payment</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { Months } from "@/constants/Months";
import { toCurrency, formatDateString } from "@/global/Helpers";
import { useNotificationStore } from '@dafcoe/vue-notification';
import { ErrorAlerts } from "@/constants/ErrorAlerts";
import StepNav from "@/components/StepNav/StepNav";
import ContractBreakdown from "@/components/Breakdown/ContractBreakdown";
import MultiSelect from "@/components/Select/MultiSelect";

export default {
  name: "PaymentBreakdown",
  setup() {
    const store = useStore();
    const { setNotification } = useNotificationStore();
    const contractsToPay = computed(() => store.getters['contracts/getContractsForPayment']);
    const monthsAhead = computed(() => store.getters['payment/getMonthsAhead']);
    const aggregateTotal = computed(() => store.getters['contracts/getTotalCost']);
    const prepayList = computed(() => store.getters['contracts/getLongestUnitPayListWithoutZeros']);
    const updateMonthsAhead = (monthsAhead) => store.dispatch('payment/updateMonthsAhead', monthsAhead);
    const updateMonthsAheadDisplay = (monthsAheadDisplay) => store.dispatch('payment/updateMonthsAheadDisplay', monthsAheadDisplay);
    const updatePayment = (payment) => store.dispatch('payment/updatePaymentInfo', payment);
    const updateTotal = (total) => store.dispatch('payment/updateTotal', total);

    return {
      setNotification,
      contractsToPay,
      monthsAhead,
      aggregateTotal,
      prepayList,
      updateMonthsAhead,
      updateMonthsAheadDisplay,
      updatePayment,
      updateTotal
    }
  },
  components: {
    StepNav,
    ContractBreakdown,
    MultiSelect
  },
  computed: {
    monthsSelectorData() {
      let months = [];

      for(let i = 0; i < this.prepayList.length; i++) {
        months.push(`${i} Month(s) (through ${formatDateString(this.prepayList[i].breakdownBalancePaidNextDueDate)})`);
      }
      return months;
    }
  },
  data() {
    return {
      months: Months
    }
  },
  methods: {
    formatCurrency(value) {
      return toCurrency(value);
    },
    submitForm(event) {
      event.preventDefault();
      this.contractsToPay.forEach(contract => {
        const contractTotal = contract.contractUnits.map(unit =>
          unit.unitPaymentBreakdowns[this.monthsAhead].breakdownBalance)
          .reduce((a, b) => a + b, 0);
        this.updatePayment({
          contractId: contract.contractId,
          customerId: contract.customerId,
          entityId: contract.entityId,
          contractUnitIds: contract.contractUnits.map(unit => unit.contractUnitId),
          paymentAmount: contractTotal,
          monthsIntoFuture: this.monthsAhead,
          onAutoPay: false,
          autopay: false
        });
      });
      this.updateTotal(this.aggregateTotal);
      if (this.aggregateTotal === 0) {
        this.setNotification({
          ...ErrorAlerts,
          message: "Please select options with a balance to pay in order to proceed."
        });
      } else {
        this.$router.push({ path: '/payment' });
      }
    },
    onSelect(data) {
      if(data !== 'Prepay') {
        this.updateMonthsAheadDisplay(data);
        this.updateMonthsAhead(parseInt(data.replace(/\s.*$/, '')));
      } else {
        this.updateMonthsAheadDisplay('');
        this.updateMonthsAhead(0);
      }
    },
    disabled(option) {
      return this.prepayList[parseInt(option.replace(/\s.*$/, ''))].balanceDue === 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.breakdown-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 600px;
  grid-template-rows: 35px 70px auto;
  grid-template-areas: "title" "stepNav" "breakdown";
  padding: 36px 36px 10px 36px;
  justify-content: center;

  &__title {
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
  }
}

.grid-row {
  position: relative;
}

.grid-row:first-of-type {
  grid-area: title;
}

.grid-row:nth-of-type(2) {
  grid-area: stepNav;
}

.grid-row:nth-of-type(3) {
  grid-area: breakdown;
}

.total-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 600px;
  grid-template-rows: auto auto 70px;
  padding: 20px 36px 40px 36px;
  background-color: $lightest-grey;
  justify-content: center;

  &__prepay-label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  &__total {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  &__label {
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
  }

  &__value {
    font-size: 22px;
    font-weight: bold;
    line-height: 25px;
    color: $primary-color;
  }

  &__button {
    position: relative;
  }
}

@media(max-width: $media-large) {
  .breakdown-container {
    grid-template-columns: 350px;
  }

  .total-container {
    grid-template-columns: 350px;
  }
}
</style>
