import Contract from '@/api/Contract';

export const addSelectedContract = ({ commit }, contractInfo) => {
  commit('addUnitToSelectedContract', contractInfo);
};

export const removeSelectedContract = ({ commit }, contractInfo) => {
  commit('removeUnitFromSelectedContract', contractInfo);
};

export const fetchContractsForCustomer = async ({ commit }, contractDto) => {
  const data = await Contract.getCustomerContracts(contractDto);
  commit('setContracts', data.data.contracts);
};

export const reset = ({commit}) => {
  commit('reset');
};
