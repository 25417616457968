export default {
  setUser(state, user) {
    state.user = {
      ...state.user,
      ...user
    }
  },

  setCustomer(state, customer) {
    state.customer = {
      ...state.customer,
      ...customer
    }
  },

  setLockout(state, lockoutDto) {
    state.lockout = {...lockoutDto};
    state.lockoutRemaining = lockoutDto.lockOutTimeRemaining
  },

  updateLockout(state, time) {
    state.lockoutRemaining = time;
  },

  setLoginData(state, loginDto) {
    state.login = {...loginDto};
  },

  setUsername(state, username) {
    state.username = username;
  },

  reset(state) {
    const s = {
      user: {
        firstName: '',
        lastName: '',
        phoneNumber: null,
        emailAddress: '',
        unitNumber: null
      },
      customer: {
        address: '',
        centralCustomerId: '',
        customerId: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        phoneNumber: null
      },
      login: {
        phoneNumber: '',
        email: '',
        password: '',
        verificationType: ''
      }
    }

    Object.keys(s).forEach(key => {
      state[key] = s[key]
    });
  }
}
