import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';

const state = {
  payments: {
    /*
    [contractId]: {
     contractId: '',
     customerId: '',
     entityId: '',
     ContractUnitIds: [],
     paymentAmount: 0,
     monthsIntoFuture: 0,
     onAutopay: false,
     autopay: false,
   }
   */
  },
  cardInfo: {
    nameOnCard: '',
    creditCardNumber: '',
    cardSecurityCode: '',
    expirationDate: '',
    cardType: ''
  },
  monthsAhead: 0,
  monthsAheadDisplay: '',
  paymentTotal: 0,
  receiptIds: [],
  receipts: [],
  savedPaymentMethods: []
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
