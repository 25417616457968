<template>
  <div class="contract-breakdown-container">
    <div class="contract-breakdown-container__title">Contract Number: {{ formatContract }}</div>
    <unit-breakdown v-for="(unit) in contract.contractUnits"
                    :unit="unit"
                    :months-ahead="monthsAhead"
                    :key="unit.contractUnitId" />
  </div>
</template>

<script>
import UnitBreakdown from "@/components/Breakdown/UnitBreakdown";

export default {
  name: "ContractBreakdown",
  props: {
    contract: Object,
    monthsAhead: Number
  },
  components: {
    UnitBreakdown
  },
  computed: {
    formatContract() {
      return `#${this.contract.entityId}-${this.contract.contractNumber}`
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-breakdown-container {
  margin-bottom: 30px;

  &__title {
    text-align: center;
    color: $black;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 30px;
  }
}
</style>
