<template>
  <div class="form-container">
    <div class="form-container__field-container form-container__header">
      Bill Pay
    </div>
    <div class="form-container__field-container form-container__message">
      Please fill out all fields below to continue.
    </div>
    <div class="form-container__field-container">
      <label for="firstName">First Name</label>
      <input id="firstName" maxlength="100" type="text" v-model="form.firstName"/>
    </div>
    <div class="form-container__field-container">
      <label for="lastName">Last Name</label>
      <input id="lastName" maxlength="100" type="text" v-model="form.lastName"/>
    </div>
    <div class="form-container__field-container">
      <label for="phoneNumber">Phone Number</label>
      <input
        id="phoneNumber"
        maxlength="15"
        type="text"
        :value="form.phoneNumber"
        @accept="event => form.phoneNumber = event.detail.value"
        v-imask="phoneNumberMask"
      />
    </div>
    <div class="form-container__field-container">
      <label for="emailAddress">Email Address</label>
      <input id="emailAddress" maxlength="256" type="email" v-model="form.emailAddress"/>
    </div>
    <div class="form-container__field-container">
      <label for="unitNumber">Your Storage Unit Number (enter any one of the units)</label>
      <input id="unitNumber" maxlength="100" type="text" v-model="form.unitNumber"/>
    </div>
    <div class="form-container__field-container">
      <button id="submitBtn" class="btn-primary" @click="submitForm">Next</button>
    </div>
    <the-footer class="form-container__field-container"/>
  </div>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import IMaskDirective from '@/directives/IMaskDirective';
import {useNotificationStore} from '@dafcoe/vue-notification';
import {ErrorAlerts} from "@/constants/ErrorAlerts";
import {hasEmptyValues} from "@/global/Helpers";
import TheFooter from "@/components/SingleTime/TheFooter";
import {formatDateString} from "@/global/Helpers";

export default {
  name: "UserInfo",
  setup() {
    const store = useStore();
    const {setNotification} = useNotificationStore();
    const user = computed(() => store.getters['user/currentUser']);
    const location = computed(() => store.getters['location/getLocation']);
    const entity = computed(() => store.getters['location/getEntity']);
    const locationId = computed(() => store.getters["location/getLocationId"]);
    const checkCustomerFetch = computed(() => store.getters['user/checkCustomerFetch']);
    const aggregateTotal = computed(() => store.getters['contracts/getTotalCost']);
    const contracts = computed(() => store.getters['contracts/getContracts']);
    const customer = computed(() => store.getters['user/currentCustomer']);
    const contractsToPay = computed(() => store.getters['contracts/getContractsForPayment']);
    const clearAll = () => store.dispatch('clearAll');
    const updateUser = (user) => store.dispatch('user/createUser', user);
    const updateLocationId = (locationId) => store.dispatch('location/updateLocationId', locationId);
    const fetchLocation = (locationId) => store.dispatch('location/fetchLocation', locationId);
    const fetchCustomer = (customerDto) => store.dispatch('user/fetchCustomer', customerDto);
    const fetchContracts = (contractDto) => store.dispatch('contracts/fetchContractsForCustomer', contractDto);
    const fetchLocationByEntity = (entityId) => store.dispatch('location/fetchLocationByEntity', entityId);
    const updateSelectedContracts = (contractInfo) => store.dispatch('contracts/addSelectedContract', contractInfo);
    const updateMonthsAhead = (monthsAhead) => store.dispatch('payment/updateMonthsAhead', monthsAhead);
    const updateMonthsAheadDisplay = (monthsAheadDisplay) => store.dispatch('payment/updateMonthsAheadDisplay', monthsAheadDisplay);
    const updatePayment = (payment) => store.dispatch('payment/updatePaymentInfo', payment);
    const updateTotal = (total) => store.dispatch('payment/updateTotal', total);

    return {
      user,
      location,
      locationId,
      entity,
      checkCustomerFetch,
      setNotification,
      contracts,
      customer,
      aggregateTotal,
      contractsToPay,
      clearAll,
      updateUser,
      updateLocationId,
      fetchLocation,
      fetchCustomer,
      fetchContracts,
      fetchLocationByEntity,
      updateSelectedContracts,
      updateMonthsAhead,
      updateMonthsAheadDisplay,
      updatePayment,
      updateTotal
    }
  },
  mounted() {
    const id = this.$route.query.locationId;
    const entityId = this.$route.query.entityId;
    const guest = this.$route.query.guest;

    if (id) {
      if (id !== this.locationId) {
        this.clearAll();
        localStorage.clear();
      }

      this.fetchLocation(id);
      this.updateLocationId(id);

    } else if (entityId) {
      this.clearAll();
      localStorage.clear();

      this.fetchLocationByEntity(entityId);
      this.processAmsCustomer(entityId);
      this.$router.push({path: '/payment'});
    } else {
      this.setNotification({
        ...ErrorAlerts,
        message: 'Error: Location ID was not found in the URL'
      });
    }

    if(!guest && !this.customer.customerId) {
      this.$router.push({ path: '/login' });
    }
  },
  components: {
    TheFooter
  },
  data() {
    return {
      form: {...this.user},
      phoneNumberMask: {
        mask: "(000) 000-0000"
      },
    };
  },
  methods: {
    async submitForm(event) {
      try {
        event.preventDefault();
        if (hasEmptyValues(this.form)) {
          this.setNotification({
            ...ErrorAlerts,
            message: 'You must fill out all of the fields.'
          });
          return;
        }
        await this.updateUser(this.form);
        await this.fetchCustomer({
          unitNumber: this.form.unitNumber,
          entityId: this.entity,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          phoneNumber: this.form.phoneNumber,
          emailAddress: this.form.emailAddress});
        if (this.checkCustomerFetch)  {
          await this.$router.push({path: '/contract'});
        } else {
          this.setNotification({
            ...ErrorAlerts,
            message: 'Error: Retrieved customer doesn\'t match the information provided'
          });
          localStorage.clear();
        }
      } catch (error) {
        this.setNotification({
          ...ErrorAlerts,
          message: error.response.data.errorMessage
        });
      }
    },
    async processAmsCustomer(entityId) {
      let contractUnitIds = this.$route.query.contractUnitIds.split(",");
      let customerId = this.$route.query.customerId;
      let centralCustomerId = this.$route.query.centralCustomerId;
      let monthsAhead = this.$route.query.monthsIntoFuture;

      await this.fetchCustomer({unitNumber: contractUnitIds[0], entityId: entityId});
      await this.fetchContracts({
        customerId: customerId, centralCustomerId: centralCustomerId,
        entityId: entityId
      });
      const contracts = Object.values(this.contracts);

      contracts.forEach((contract) => {
        contract.contractUnits.filter((unit) => contractUnitIds.includes(unit.unitNumber))
          .forEach((unit) => this.updateSelectedContracts({
            contractNumber: contract.contractNumber,
            contractUnitNumber: unit.unitNumber
          }));
      });

      //Build Payment
      await this.updateMonthsAhead(monthsAhead);

      const dueDate = contracts.reduce((newest, current) =>
        new Date(newest.dueDate).getTime() < new Date(current.dueDate).getTime() ? newest : current).dueDate;

      let month = new Date(Date.parse(dueDate));
      month.setMonth(month.getMonth() + parseInt(monthsAhead))
      const monthsAheadDisplay = `${monthsAhead} Month(s) through ${formatDateString(month)}`;
      await this.updateMonthsAheadDisplay(monthsAheadDisplay);

      this.contractsToPay.forEach(contract => {
        const contractTotal = contract.contractUnits.map(unit =>
          unit.unitPaymentBreakdowns[monthsAhead].breakdownBalance)
          .reduce((a, b) => a + b, 0);
        this.updatePayment({
          contractId: contract.contractId,
          customerId: contract.customerId,
          entityId: contract.entityId,
          contractUnitIds: contract.contractUnits.map(unit => unit.contractUnitId),
          paymentAmount: contractTotal,
          monthsIntoFuture: monthsAhead,
          onAutoPay: false,
          autopay: false
        });
      });

      await this.updateTotal(this.aggregateTotal);
    }
  },
  directives: {
    imask: IMaskDirective,
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto;
  grid-template-rows: 45px 45px 90px 90px 90px 90px 90px 90px;
  grid-template-areas: "header header" "message message" "firstName lastName" "phoneNumber phoneNumber" "emailAddress emailAddress" "unitNumber unitNumber" "submitBtn submitBtn" "footerLogo footerLogo";
  justify-content: center;
  padding: 36px 36px 10px 36px;
  color: $black;

  &__header {
    text-align: center;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 600;
    font-size: 26px;
  }

  &__message {
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 0;
  }
}

.form-container__field-container:first-of-type {
  grid-area: header;
}

.form-container__field-container:nth-of-type(2) {
  grid-area: message;
}

.form-container__field-container:nth-of-type(3) {
  grid-area: firstName;
}

.form-container__field-container:nth-of-type(4) {
  grid-area: lastName;
}

.form-container__field-container:nth-of-type(5) {
  grid-area: phoneNumber;
}

.form-container__field-container:nth-of-type(6) {
  grid-area: emailAddress;
}

.form-container__field-container:nth-of-type(7) {
  grid-area: unitNumber;
}

.form-container__field-container:nth-of-type(8) {
  grid-area: submitBtn;
}

.form-container__field-container:nth-of-type(9) {
  grid-area: footerLogo;
}

@media(max-width: $media-large) {
  .form-container {
    max-width: 350px;
  }
}
</style>
