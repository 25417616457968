export const getLocation = state => {
  return state.location;
};

export const getEntity = state => {
  return state.location.entity;
};

export const getLocationId = state => {
  return state.locationId;
};

export const getLocationAutopayRule = state => {
  return state.location.allowAutopay;
}

export const getLocationAcceptedPaymentTypes = state => {
  return state.location.acceptedPaymentTypes;
}
