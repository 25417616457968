<template>
  <div class="unit-container" :class="{ 'unit-container--checked': checked }">
    <div class="unit-container__check">
      <checkbox @check-clicked="handleCheck" :checked="checked"/>
      <div class="unit-container__unit-number">{{ contractUnit.unitNumber }}</div>
    </div>
    <div class="unit-container__pricing">
      <div class="pricing__balance">Balance: {{ formatCurrency(contractUnit.nextAmountDue) }}</div>
      <div class="pricing__due-date">
        <span v-if="checkPrepay(contractUnit.dateUnitPaidThrough)">Paid Through</span>
        <span v-else>Due on</span>
        {{ contractUnit.dueDate }}
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { toCurrency, isPrepaid } from "@/global/Helpers";
import Checkbox from "@/components/Select/UnitSelect/Checkbox";

export default {
  name: "CheckSelect",
  components: {
    Checkbox
  },
  props: {
    contractNumber: String,
    contractUnit: Object
  },
  setup() {
    const store = useStore();
    const selectedContractUnits = computed(() => store.getters['contracts/getSelectedContractUnitsFlat']);
    const updateSelectedContracts = (contractInfo) => store.dispatch('contracts/addSelectedContract', contractInfo);
    const removeSelectedContract = (contractInfo) => store.dispatch('contracts/removeSelectedContract', contractInfo);
    return {
      selectedContractUnits,
      updateSelectedContracts,
      removeSelectedContract
    }
  },
  data() {
    return {
      checked: this.selectedContractUnits.includes(this.contractUnit.unitNumber)
    }
  },
  methods: {
    handleCheck(data) {
      this.checked = data;
      const contractInfo = {
        contractNumber: this.contractNumber,
        contractUnitNumber: this.contractUnit.unitNumber
      };

      if(this.checked) {
        this.updateSelectedContracts(contractInfo);
      } else {
        this.removeSelectedContract(contractInfo);
      }
    },
    formatCurrency(value) {
      return toCurrency(value);
    },
    checkPrepay(date) {
      return isPrepaid(date);
    }
  }
}
</script>

<style lang="scss" scoped>
.unit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $light-grey;
  padding: 12px 15px;
  margin: 10px 0;

  &__check {
    display: flex;
    align-items: center;
  }

  &__unit-number {
    color: $black;
    font-size: 16px;
    line-height: 20px;
  }

  &--checked {
    border: 2px solid $primary-color;
    background-color: $secondary-color;
  }
}

.pricing__balance {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: $black;
  text-align: right;
  margin-bottom: 10px;
}

.pricing__due-date {
  font-size: 14px;
  line-height: 18px;
  color: $black;
  text-align: right;
}
</style>
