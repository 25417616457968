import Customer from "@/api/Customer";
import Login from "@/api/Login";

export const createUser = ({ commit }, user) => {
  commit('setUser', user);
}

export const fetchCustomer = async ({ commit }, customerDto) => {
  const data = await Customer.getCustomer(customerDto);
  commit('setCustomer', data.data);
}

export const authenticateCustomer = async ({ commit }, loginDto) => {
  const data = await Login.loginCustomer(loginDto);
  commit('setCustomer', data.data);
}

export const fetchCustomerLockout = async ({ commit }, lockoutDto) => {
  const data = await Customer.getCustomerLockout(lockoutDto);
  commit('setLockout', data.data);
}

export const updateLockout = ({ commit }, time) => {
  commit('updateLockout', time);
}


export const createCustomerAccount = async ({ commit }, loginDto) => {
  await Login.createCustomerAccount(loginDto);
  commit('setLoginData', loginDto);
}

export const setLoginInfo = ({ commit }, loginDto) => {
  commit('setLoginData', loginDto);
}

export const setUsername = ({ commit }, username) => {
  commit('setUsername', username);
}

export const reset = ({ commit }) => {
  commit('reset');
}
