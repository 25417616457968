export default {
  setLocation(state, location) {
    state.location = {
      ...state.location,
      ...location
    };
  },
  updateLocationId(state, locationId) {
    state.locationId = locationId;
  },
  reset(state) {
    const s = {
      location: {}
    }

    Object.keys(s).forEach(key => {
      state[key] = s[key]
    });
  }
}
