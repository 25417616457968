<template>
<div class="thank-you-container">
  <div class="thank-you-container__check">
    <font-awesome-icon icon="check" />
  </div>
  <div class="thank-you-container__label">
    Thank You!
  </div>
  <div class="thank-you-container__payment-message">
    Payment of
    <span class="thank-you-container__amount">{{ formatCurrency(total) }}</span>
    has been made for your storage units
  </div>
  <div class="thank-you-container__receipt">
    <div v-for="(receiptNumber, i) in receiptNumbers" :key="i">
      <span class="thank-you-container__receipt-label">Receipt No. </span>
      <span class="thank-you-container__receipt-value">{{ receiptNumber }}</span>
    </div>
  </div>
  <div class="thank-you-container__button">
    <button id="submitBtn" class="btn btn-primary" @click="submitForm">View Receipt</button>
  </div>
  <the-footer />
</div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import TheFooter from "@/components/SingleTime/TheFooter";
import { toCurrency } from "@/global/Helpers";

export default {
  name: "Confirmation",
  setup() {
    const store = useStore();
    const total = computed(() => store.getters['payment/getPaymentTotal']);
    const receiptIds = computed(() => store.getters['payment/getReceiptIds']);
    const receiptNumbers = computed(() => store.getters['payment/getReceiptNumbers']);
    const getReceipts = (receiptIds) => store.dispatch('payment/fetchReceipts', receiptIds);

    return {
      total,
      receiptIds,
      receiptNumbers,
      getReceipts
    }
  },
  async mounted() {
    await this.getReceipts(this.receiptIds)
  },
  components: {
    TheFooter
  },
  methods: {
    submitForm() {
      this.$router.push({ path: '/receipts' });
    },
    formatCurrency(value) {
      return toCurrency(value);
    }
  }
}
</script>

<style lang="scss" scoped>
.thank-you-container {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 30px;
  grid-template-columns: 600px;
  grid-template-rows: auto auto auto auto 70px auto;
  padding: 36px 36px 10px 36px;
  justify-content: center;

  &__check {
    text-align: center;
  }

  &__label {
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
  }

  &__payment-message {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    color: $dark-grey;
    text-align: center;
  }

  &__amount {
    color: $primary-color;
  }

  &__receipt {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }
  &__button {
    position: relative;
  }
}

.fa-check {
  border-radius: 50%;
  background-color: $primary-color;
  color: $white;
  font-size: 54px;
  line-height: 22px;
  padding: 10px 10px;
  margin-top: 95px;
}

@media(max-width: $media-large) {
  .thank-you-container {
    grid-template-columns: 350px;
  }
}
</style>
