import { createLogger, createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from './User';
import contracts from './Contracts';
import location from './Location';
import payment from './Payment';
import navigation from './Nav';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  actions: {
    clearAll({dispatch}) {
      dispatch('user/reset');
      dispatch('location/reset');
      dispatch('contracts/reset');
      dispatch('payment/reset');
    }
  },
  modules: {
    user,
    contracts,
    location,
    payment,
    navigation
  },
  strict: debug,
  plugins: debug
    ? [createLogger(), createPersistedState()]
    : [createPersistedState()]
});
