<template>
  <div class="cardform-container">
    <credit-card class="credit-card"
                 :name="name"
                 :card-number="cardNumber"
                 :security-code="securityCode"
                 :expiration="expirationDate"
                 :card-style="cardStyle"
                 :flipped="flipped"/>
    <div class="form-container">
      <div class="form-container__field-container form-container__encrypted">
        <font-awesome-icon icon="lock"/>
        Encrypted Payment
      </div>
      <div class="form-container__field-container">
        <label>Saved Cards</label>
        <MultiSelect :options="savedCards.map(c => c.accountNumber)" @input="onSelectSavedCard" default="Select"></MultiSelect>
      </div>
      <div class="form-container__field-container">
        <label for="name">Full Name</label>
        <input id="name" maxlength="20" type="text" v-model="name"/>
      </div>
      <div class="form-container__field-container">
        <label for="cardnumber">Card Number</label>
        <input
          id="cardnumber"
          type="text"
          pattern="[0-9]*"
          inputmode="numeric"
          :value="cardNumber"
          v-imask="cardNumberMask"
          @accept="onAcceptCardNumber"
          @complete="onCompleteCardNumber"
        />
        <svg
          id="ccicon"
          class="ccicon"
          width="750"
          height="471"
          viewBox="0 0 750 471"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          v-html="cardStyle.svg"
        ></svg>
      </div>
      <div class="form-container__field-container">
        <label for="expirationdate">Expiration (mm/yy)</label>
        <input
          id="expirationdate"
          type="text"
          pattern="[0-9]*"
          inputmode="numeric"
          :value="expirationDate"
          v-imask="expirationDateMask"
          @accept="onAcceptExpirationDate"
        />
      </div>
      <div class="form-container__field-container">
        <label for="securitycode">Security Code</label>
        <input
          id="securitycode"
          type="password"
          pattern="[0-9]*"
          inputmode="numeric"
          v-on:focusin="flipped = true"
          v-on:focusout="flipped = false"
          :value="securityCode"
          v-imask="securityCodeMask"
          @accept="onAcceptSecurityCode"/>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import useVuelidate from "@vuelidate/core";
import { required } from '@vuelidate/validators'
import { useNotificationStore } from '@dafcoe/vue-notification';
import { ErrorAlerts } from "@/constants/ErrorAlerts";
import IMask from 'imask';
import IMaskDirective from '@/directives/IMaskDirective';
import CardTypeDisplay from '@/constants/CardTypeDisplay';
import CreditCard from "@/components/CreditCard/CreditCard";
import MultiSelect from "@/components/Select/MultiSelect.vue";



export default {
  name: "CardForm",
  setup() {
    const store = useStore();
    const { setNotification } = useNotificationStore();
    const savedPaymentMethods = computed(() => store.getters['payment/getSavedPaymentMethods']);
    const cardInfo = computed(() => store.getters['payment/getCardInfo']);
    const userFullName = computed(() => store.getters['user/userFullName']);
    const customerFullName = computed(() => store.getters['user/getCustomerFullName']);
    const contracts = computed(() => store.getters['contracts/getContracts']);
    const customer = computed(()=> store.getters['user/currentCustomer'])
    const entity = computed(() => store.getters['location/getEntity'])
    const location = computed(() => store.getters['location/getLocation']);
    const updateCardInfo = (cardInfo) => store.dispatch('payment/updateCardInfo', cardInfo);
    const submitPayment = (payment) => store.dispatch('payment/submitPayment', payment);
    const getSavedPaymentMethods = (getPaymentMethodsDto) => store.dispatch('payment/getPaymentMethods', getPaymentMethodsDto);


    return {
      setNotification,
      savedPaymentMethods,
      cardInfo,
      userFullName,
      customerFullName,
      contracts,
      customer,
      entity,
      location,
      updateCardInfo,
      submitPayment,
      getSavedPaymentMethods,
    }
  },
  components: {
    MultiSelect,
    CreditCard,
  },
  data() {
    return {
      v$: useVuelidate(),
      loading: false,
      name: this.customer.isGuest ? this.userFullName : this.customerFullName,
      cardNumber: "",
      cardNumberMask: {
        mask: [
          {
            mask: "0000 000000 00000",
            regex: /^3[47]\d{0,13}$/,
            cardtype: "american express",
          },
          {
            mask: "0000 00aaaaaaaaa 0000",
            regex: /^3[47]\d{0,4}[A-Z]{0,9}\d{0,4}$/,
            cardtype: "american express",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: /^6(?:011|5\d{0,2})\d{0,12}$/,
            cardtype: "discover",
          },
          {
            mask: "0000 00aaaaaaaaa 0000",
            regex: /^6(?:011|5\d{0,2})\d{0,2}[A-Z]{0,9}\d{0,4}$/,
            cardtype: "discover",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}$/,
            cardtype: "mastercard",
          },
          {
            mask: "0000 00aaaaaaaaa 0000",
            regex: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,2}[A-Z]{0,9}\d{0,4}$/,
            cardtype: "mastercard",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: /^4\d{0,15}$/,
            cardtype: "visa",
          },
          {
            mask: "0000 00aaaaaaaaa 0000",
            regex: "^4\\d{0,5}[A-Z]{0,9}\\d{0,4}$",
            cardtype: "visa",
          },
          {
            mask: "0000 0000 0000 0000",
            cardtype: "Unknown",
          },
        ],
        dispatch(appended, dynamicMasked) {
          let number = (dynamicMasked.value + appended).replace(/[^0-9A-Z]/g, "");

          for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
            let re = new RegExp(dynamicMasked.compiledMasks[i].regex);
            if (number.match(re) != null) {
              return dynamicMasked.compiledMasks[i];
            }
          }
        }
      },
      expirationDate: "",
      expirationDateMask: {
        mask: "MM{/}YY",
        blocks: {
          YY: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 99,
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 12,
          },
        },
      },
      securityCode: null,
      securityCodeMask: {
        mask: "0000",
      },
      savedCards: this.savedPaymentMethods,
      savePayment: false,
      cardType: '',
      cardStyle: CardTypeDisplay['Unknown'],
      flipped: false,
    };
  },
  validations() {
    return {
      name: { required },
      cardNumber: { required },
      expirationDate: { required },
      securityCode: { required },
    };
  },
  methods: {
    submitForm() {
      try {
        this.loading = true;
        let accepted = Object.values(this.location.acceptedPaymentTypes);
        this.v$.$validate();
        if (this.v$.$error) {
          this.loading = false;
          this.setNotification({
            ...ErrorAlerts,
            message: "A form field was not filled out"
          });
          return false;
        }

        if (!accepted.includes(this.cardType)) {
          this.loading = false;
          this.setNotification({
            ...ErrorAlerts,
            message: `This location does not accept ${this.cardType} cards`
          });
          return false;
        }

        const expirationArr = this.expirationDate.split('/');
        const expiration = `20${expirationArr[1]}-${expirationArr[0]}-01`;

        this.updateCardInfo({
          nameOnCard: this.name,
          creditCardNumber: this.cardNumber.replace(/\s+/g, ''),
          cardSecurityCode: this.securityCode,
          expirationDate: expiration,
          cardType: this.cardType
        });

        return true;
      } catch (error) {
        this.loading = false;
        this.setNotification({
          ...ErrorAlerts,
          message: error.response ? error.response.data.errorMessage : "A form field was not filled out"
        });
        return false;
      }
    },
    onAcceptCardNumber(event) {
      const maskRef = event.detail;
      this.cardNumber = maskRef.value;
      this.$log.info(`card number accepted: ${this.cardNumber}`);
    },
    onCompleteCardNumber(event) {
      const maskRef = event.detail;
      const cardType = maskRef.masked.currentMask.cardtype;

      this.cardStyle = CardTypeDisplay[cardType];
      this.cardType = cardType;
    },
    onAcceptExpirationDate(event) {
      const maskRef = event.detail;
      this.expirationDate = maskRef.value;
      this.$log.info(`expiration date accepted: ${this.expirationDate}`);
    },
    onAcceptSecurityCode(event) {
      const maskRef = event.detail;
      this.securityCode = maskRef.value;
      this.$log.info(`security code accepted: ${this.securityCode}`)
    },
    onSelectSavedCard(data){
      if(data === "Select"){
        this.clearSelectedCard();
      } else {
        this.cardNumber = data;
        this.expirationDate = this.savedCards.find(c => c.accountNumber === data).expirationDate;
      }
    },
    clearSelectedCard(){
      this.cardNumber = "";
      this.expirationDate = "";
      this.securityCode = "";
      this.cardStyle = CardTypeDisplay['Unknown'];
    }
  },
  directives: {
    imask: IMaskDirective,
  },
  async created() {
    await this.getSavedPaymentMethods({"centralCustomerId": this.customer.centralCustomerId, "entity": this.entity})
    this.savedCards = this.savedPaymentMethods;
  },
};
</script>

<style lang="scss" scoped>
.cardform-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 600px;
  grid-template-rows: auto;
  padding: 20px 36px 40px 36px;
  justify-content: center;
}

.credit-card {
  width: 100%;
}

.form-container {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  grid-template-columns: 66% 33%;
  grid-template-rows: auto auto auto auto auto;
  grid-template-areas:
    "encrypted encrypted"
    "cards cards"
    "name name"
    "number number"
    "expiration security"
    "checkbox checkbox";
  justify-content: center;

  &__encrypted {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  &__field-container:nth-of-type(1) {
    grid-area: encrypted;
  }

  &__field-container:nth-of-type(2) {
    grid-area: cards;
  }

  &__field-container:nth-of-type(3) {
    grid-area: name;
  }

  &__field-container:nth-of-type(4) {
    grid-area: number;
  }

  &__field-container:nth-of-type(5) {
    grid-area: expiration;
  }

  &__field-container:nth-of-type(6) {
    grid-area: security;
  }

  &__field-container:nth-of-type(7) {
    grid-area: checkbox;
  }

  &__field-container:nth-of-type(8) {
    grid-area: submitBtn;
  }
}
.ccicon {
  height: 38px;
  position: absolute;
  right: 6px;
  top: calc(50% - 8px);
  width: 60px;
}

@media(max-width: $media-large) {
  .cardform-container {
    grid-template-columns: 350px;
  }
}
</style>
