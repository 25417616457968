import Axios from "axios";
import {API_ROOT} from "@/constants/ApiRoot";

const CUSTOMER_ENDPOINT = `${API_ROOT}/customer`;

export default {
  async getCustomer(customerDto) {
    return await Axios.get(`${CUSTOMER_ENDPOINT}`, {
      params: customerDto
    });
  },

  async getCustomerLockout(lockoutDto) {
    return await Axios.get(`${CUSTOMER_ENDPOINT}/lockout`, {
      params: lockoutDto
    });
  }
}
