<template>
  <div class="storage-unit-container">
    <div class="form-container">
      <div class="form-container__field-container form-container__title">Select Storage Unit</div>
      <step-nav class="form-container__field-container" :active="1" />
      <div class="form-container__field-container">
        <div id="contractSelect" class="contract-select-container">
          <div class="contract-select-container__label">Choose Your Contract Number:</div>
          <multi-select v-if="contractNumbers.length" @input="handleContractSelect" :options="contractNumbers" :default="defaultSelect" />
        </div>
      </div>
      <div class="form-container__field-container">
        <unit-group v-if="contract"
                    :contract-number="selectedContract"
                    :contract-units="contract.contractUnits" />
      </div>
    </div>
    <div class="total-container">
      <div class="total-container__subtotal">
        <div class="subtotal__label">Subtotal:</div>
        <div class="subtotal__price">{{ formatCurrency(total) }}</div>
      </div>
      <div class="total-container__disclaimer">
        Prepayment option is available on next screen.
      </div>
      <div class="total-container__button">
        <button id="submitBtn" class="btn btn-primary" @click="submitForm">Continue to Summary</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useNotificationStore } from '@dafcoe/vue-notification';
import { ErrorAlerts } from "@/constants/ErrorAlerts";
import { toCurrency } from "@/global/Helpers";
import StepNav from "@/components/StepNav/StepNav";
import MultiSelect from '@/components/Select/MultiSelect';
import UnitGroup from "@/components/Select/UnitSelect/UnitGroup";

export default {
  name: "ContractSelect",
  components: {
    StepNav,
    MultiSelect,
    UnitGroup
  },
  setup() {
    const store = useStore();
    const { setNotification } = useNotificationStore();
    const selectedContractUnits = computed(() =>
      store.getters['contracts/getSelectedContractUnitsFlat']);
    const customer = computed(() => store.getters['user/currentCustomer']);
    const entity = computed(() => store.getters['location/getEntity']);
    const contracts = computed(() => store.getters['contracts/getContracts']);
    const total = computed(() => store.getters['contracts/getTotalCost']);
    const fetchContracts = (contractDto) => store.dispatch('contracts/fetchContractsForCustomer', contractDto);

    return {
      selectedContractUnits,
      customer,
      entity,
      contracts,
      total,
      setNotification,
      fetchContracts
    }
  },
  mounted() {
    try {
      this.fetchContracts({ customerId: this.customer.customerId, centralCustomerId: this.customer.centralCustomerId,
        entityId: this.entity });
    } catch(error) {
      this.setNotification({
        ...ErrorAlerts,
        message: error.response.data.errorMessage
      });
    }
  },
  data() {
    return {
      selectedContract: '',
    }
  },
  computed: {
    contractNumbers() {
      return Object.values(this.contracts).map(contract => `#${this.entity}-${contract.contractNumber}`);
    },
    contract() {
      return Object.values(this.contracts).find(contract => contract.contractNumber === this.selectedContract);
    },
    defaultSelect() {
      return this.contractNumbers.length >= 1 ? this.contractNumbers[0] : 'Select';
    }
  },
  methods: {
    handleContractSelect(data) {
      this.selectedContract = data.replace(/^.+-/, '');
    },
    formatCurrency(value) {
      return toCurrency(value);
    },
    submitForm(event) {
      event.preventDefault();
      if(Object.keys(this.selectedContractUnits).length === 0) {
        this.setNotification({
          ...ErrorAlerts,
          message: 'You must select at least one unit to continue.'
        })
      } else {
        this.$router.push({ path: '/paymentBreakdown' });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 600px;
  grid-template-rows: 45px 80px 80px auto 100px;
  grid-template-areas: "title" "stepNav" "contractSelect" "unitSelect" "submitBtn";
  padding: 36px 36px 10px 36px;
  justify-content: center;
  color: $black;

  &__title {
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    color: $black;
    text-align: center;
  }
}

.form-container__field-container:first-of-type {
  grid-area: title;
}

.form-container__field-container:nth-of-type(2) {
  grid-area: stepNav;
}

.form-container__field-container:nth-of-type(3) {
  grid-area: contractSelect;
}

.form-container__field-container:nth-of-type(4) {
  min-height: 200px;
  grid-area: unitSelect;
}

.form-container__field-container:nth-of-type(5) {
  grid-area: submitBtn;
}

.total-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 600px;
  grid-template-rows: auto auto 70px;
  padding: 20px 36px 40px 36px;
  background-color: $lightest-grey;
  justify-content: center;

  &__button {
    position: relative;
  }

  &__subtotal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }
}

.subtotal__label {
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  color: $black;
}

.subtotal__price {
  font-size: 22px;
  line-height: 25px;
  font-weight: bold;
  color: $primary-color;
}

.total-container__disclaimer {
  font-size: 16px;
  line-height: 20px;
  color: $black;
  margin-bottom: 20px;
}

.contract-select-container {
  height: 100%;

  &__label {
    font-size: 16px;
    line-height: 20px;
    color: $black;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media(max-width: $media-large) {
  .form-container {
    grid-template-columns: 350px;
  }

  .total-container {
    grid-template-columns: auto;
  }
}
</style>
