<template>
  <div class="footer-container">
    <div class="footer-container__logo">
      <img src="/USSAN.png" alt="Powered By USSAN"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter"
}
</script>

<style lang="scss" scoped>
.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__logo {
    margin-bottom: 20px;
  }

  &__logo img {
    width: 86px;
    height: 50px;
  }
}
</style>
