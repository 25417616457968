export const currentUser = state => {
  return state.user;
}

export const checkCustomerFetch = state => {
  return state.customer.firstName != null &&
    state.customer.lastName != null &&
    state.customer.emailAddress != null;
}
export const currentCustomer = state => {
  return state.customer;
}

export const currentLogin = state => {
  return state.login;
}

export const getUsername = state => {
  return state.username;
}

export const userFullName = state => {
  return `${state.user.firstName} ${state.user.lastName}`;
}

export const getCustomerFullName = state => {
  return `${state.customer.firstName} ${state.customer.lastName}`;
}

export const getLockout = state => {
  return state.lockoutRemaining;
}
