<template>
  <div class="unit-group-container">
    <div class="unit-group-container__label">Select Storage Unit:</div>
    <div class="unit-group-container__no-balance" v-if="contractUnits.length === 0">There is no balance due for any units under this contract.</div>
    <check-select v-for="unit in contractUnits"
                  :key="unit.contractUnitId"
                  :contract-number="contractNumber"
                  :contract-unit="unit" />
  </div>
</template>

<script>
import CheckSelect from "@/components/Select/UnitSelect/CheckSelect";
export default {
  name: "UnitGroup",
  props: {
    contractNumber: String,
    contractUnits: Array
  },
  components: {
    CheckSelect
  }
}
</script>

<style lang="scss" scoped>
.unit-group-container {
  margin: 15px 0;

  &__label {
    font-size: 16px;
    line-height: 20px;
    color: $black;
    text-align: center;
  }
  &__no-balance{
    text-align: center;
    margin-top: 15px;
    color: $error;
  }
}
</style>
