import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';

const state = {
  user: {
    firstName: '',
    lastName: '',
    phoneNumber: null,
    emailAddress: '',
    unitNumber: null
  },
  customer: {
    address: '',
    centralCustomerId: '',
    customerId: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    phoneNumber: null,
    isGuest: true,
  },
  login: {
    phoneNumber: '',
    email: '',
    password: '',
    verificationType: ''
  },
  lockout: {},
  lockoutRemaining: 0,
  // this state shouldn't be cleared by session timeout
  username: ''
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
