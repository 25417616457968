<template>
  <div class="view-container">
    <div class="payment-container">
      <div class="payment-container__title">Enter Payment Information</div>
      <step-nav :active="3" />
      <credit-card class="credit-card"
                   :name="name"
                   :card-number="cardNumber"
                   :security-code="securityCode"
                   :expiration="expirationDate"
                   :card-style="cardStyle"
                   :flipped="flipped" />
      <div class="form-container">
        <div class="form-container__field-container form-container__encrypted">
          <font-awesome-icon icon="lock" />
          Encrypted Payment
        </div>
        <div class="form-container__field-container">
          <label>Saved Cards</label>
          <MultiSelect :options="savedCards.map(c => c.accountNumber)" @input="onSelectSavedCard" default="Select"></MultiSelect>
        </div>
        <div class="form-container__field-container">
          <label for="name">Full Name</label>
          <input id="name" maxlength="20" type="text" v-model="name" />
        </div>
        <div class="form-container__field-container">
          <label for="cardnumber">Card Number</label>
          <input
            id="cardnumber"
            type="text"
            pattern="[0-9]*"
            inputmode="numeric"
            :value="cardNumber"
            v-imask="cardNumberMask"
            @accept="onAcceptCardNumber"
            @complete="onCompleteCardNumber"
          />
          <svg
            id="ccicon"
            class="ccicon"
            width="750"
            height="471"
            viewBox="0 0 750 471"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            v-html="cardStyle.svg"
          ></svg>
        </div>
        <div class="form-container__field-container">
          <label for="expirationdate">Expiration (mm/yy)</label>
          <input
            id="expirationdate"
            type="text"
            pattern="[0-9]*"
            inputmode="numeric"
            :value="expirationDate"
            v-imask="expirationDateMask"
            @accept="onAcceptExpirationDate"
          />
        </div>
        <div class="form-container__field-container">
          <label for="securitycode">Security Code</label>
          <input
            id="securitycode"
            type="password"
            pattern="[0-9]*"
            inputmode="numeric"
            v-on:focusin="flipped = true"
            v-on:focusout="flipped = false"
            :value="securityCode"
            v-imask="securityCodeMask"
            @accept="onAcceptSecurityCode" />
        </div>
        <div class="form-container__field-container checkbox-container">
          <Checkbox :checked="savePayment" @checkClicked="onSaveCardCheckboxSelected" label="Save Card For Future Use?"></Checkbox>
          <Checkbox v-if="allowAutopay" :checked="addAutopay" @checkClicked="onAddAutopaySelected" label="Enroll into Autopay?"></Checkbox>
        </div>
      </div>
    </div>
    <div class="total-container" :class="[monthsAheadDisplay ? '' : 'total-container--hide-row']">
      <div class="total-container__total">
        <div class="total-container__label">Total Balance Due:</div>
        <div class="total-container__value">{{ formatCurrency(totalPayment) }}</div>
      </div>
      <div class="total-container__prepay" :class="[monthsAheadDisplay ? '' : 'total-container__hide-row']">
        <font-awesome-icon :icon="['far', 'calendar-check']" />
        Prepay for {{ monthsAheadDisplay }}
      </div>
      <div class="total-container__button">
        <button id="submitBtn" class="btn btn-primary" @click="submitForm" :disabled="loading">
          <font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
          <span v-if="!loading">Confirm & Pay {{ formatCurrency(totalPayment) }}</span>
        </button>
      </div>
    </div>
    <modal :show-modal="showModal" @close-modal="showModal = false">
      <template v-slot:header>
        <h3>Adding the below units to Autopay</h3>
      </template>
      <template v-slot:body>
        <p v-for="unit in autoPayUnits" :key="unit.contractUnitId">{{unit.unitNumber}}</p>
      </template>
      <template v-slot:footer>
        <div class="modal-container__btn-group">
          <button class="modal-container__btn-group-cancel" @click="addAutopay = false; showModal = false">Cancel</button>
          <button class="modal-container__btn-group-confirm" @click="addAutopay = true; showModal = false">Confirm</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { toCurrency } from "@/global/Helpers";
import { useNotificationStore } from '@dafcoe/vue-notification';
import { ErrorAlerts } from "@/constants/ErrorAlerts";
import IMask from 'imask';
import IMaskDirective from '@/directives/IMaskDirective';
import CardTypeDisplay from '@/constants/CardTypeDisplay';
import StepNav from "@/components/StepNav/StepNav";
import CreditCard from "@/components/CreditCard/CreditCard";
import MultiSelect from "@/components/Select/MultiSelect";
import Modal from "@/components/Modal/Modal.vue";
import Checkbox from "@/components/Select/UnitSelect/Checkbox";

export default {
  name: "Payment",
  setup() {
    const store = useStore();
    const { setNotification } = useNotificationStore();
    const totalPayment = computed(() => store.getters['payment/getPaymentTotal']);
    const payments = computed(() => store.getters['payment/getPayments']);
    const savedPaymentMethods = computed(() => store.getters['payment/getSavedPaymentMethods']);
    const cardInfo = computed(() => store.getters['payment/getCardInfo']);
    const userFullName = computed(() => store.getters['user/userFullName']);
    const monthsAheadDisplay = computed(() => store.getters['payment/getMonthsAheadDisplay']);
    const contracts = computed(() => store.getters['contracts/getContracts']);
    const customer = computed(()=> store.getters['user/currentCustomer'])
    const entity = computed(() => store.getters['location/getEntity'])
    const acceptAutopay = computed(() => store.getters['location/getLocationAutopayRule']);
    const selectedContracts = computed(() => store.getters['contracts/getContractsForPayment']);
    const updateCardInfo = (cardInfo) => store.dispatch('payment/updateCardInfo', cardInfo);
    const submitPayment = (payment) => store.dispatch('payment/submitPayment', payment);
    const getSavedPaymentMethods = (getPaymentMethodsDto) => store.dispatch('payment/getPaymentMethods', getPaymentMethodsDto);
    const savePaymentMethod = (savePaymentMethodsDto) => store.dispatch('payment/savePaymentMethod', savePaymentMethodsDto);
    const updateAutoPay = (autopayDto) => store.dispatch('payment/updateAutoPay', autopayDto);


    return {
      setNotification,
      totalPayment,
      payments,
      savedPaymentMethods,
      cardInfo,
      userFullName,
      monthsAheadDisplay,
      contracts,
      customer,
      entity,
      acceptAutopay,
      selectedContracts,
      updateCardInfo,
      submitPayment,
      getSavedPaymentMethods,
      savePaymentMethod,
      updateAutoPay
    }
  },
  components: {
    CreditCard,
    StepNav,
    MultiSelect,
    Modal,
    Checkbox
  },
  data() {
    return {
      loading: false,
      name: this.userFullName,
      cardNumber: "",
      cardNumberMask: {
        mask: [
          {
            mask: "0000 000000 00000",
            regex: /^3[47]\d{0,13}$/,
            cardtype: "american express",
          },
          {
            mask: "0000 00aaaaaaaaa 0000",
            regex: /^3[47]\d{0,4}[A-Z]{0,9}\d{0,4}$/,
            cardtype: "american express",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: /^6(?:011|5\d{0,2})\d{0,12}$/,
            cardtype: "discover",
          },
          {
            mask: "0000 00aaaaaaaaa 0000",
            regex: /^6(?:011|5\d{0,2})\d{0,2}[A-Z]{0,9}\d{0,4}$/,
            cardtype: "discover",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}$/,
            cardtype: "mastercard",
          },
          {
            mask: "0000 00aaaaaaaaa 0000",
            regex: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,2}[A-Z]{0,9}\d{0,4}$/,
            cardtype: "mastercard",
          },
          {
            mask: "0000 0000 0000 0000",
            regex: /^4\d{0,15}$/,
            cardtype: "visa",
          },
          {
            mask: "0000 00aaaaaaaaa 0000",
            regex: "^4\\d{0,5}[A-Z]{0,9}\\d{0,4}$",
            cardtype: "visa",
          },
          {
            mask: "0000 0000 0000 0000",
            cardtype: "Unknown",
          },
        ],
        dispatch(appended, dynamicMasked) {
          let number = (dynamicMasked.value + appended).replace(/[^0-9A-Z]/g, "");

          for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
            let re = new RegExp(dynamicMasked.compiledMasks[i].regex);
            if (number.match(re) != null) {
              return dynamicMasked.compiledMasks[i];
            }
          }
        }
      },
      expirationDate: "",
      expirationDateMask: {
        mask: "MM{/}YY",
        blocks: {
          YY: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 99,
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 12,
          },
        },
      },
      securityCode: null,
      securityCodeMask: {
        mask: "0000",
      },
      savedCards: this.savedPaymentMethods,
      savePayment: false,
      cardType: '',
      cardStyle: CardTypeDisplay['Unknown'],
      flipped: false,
      addAutopay: false,
      showModal: false,
      autoPayUnits: [],
      allowAutopay: false,
    };
  },
  methods: {
    formatCurrency(value) {
      return toCurrency(value);
    },
    async submitForm(event) {
      try {
        this.loading = true;
        event.preventDefault();
        const expirationArr = this.expirationDate.split('/');
        const expiration = `20${expirationArr[1]}-${expirationArr[0]}-01`;

        await this.updateCardInfo({
          nameOnCard: this.name,
          creditCardNumber: this.cardNumber.replace(/\s+/g, ''),
          cardSecurityCode: this.securityCode,
          expirationDate: expiration,
          cardType: this.cardType
        });
        await Promise.all(Object.values(this.payments)
          .map(x => this.submitPayment({ ...x, ...this.cardInfo })));

        if (this.addAutopay){
          let contractUnitIds = this.autoPayUnits.map(u => u.contractUnitId);
          await this.updateAutoPay({
            contractUnitIds: contractUnitIds,
            ...this.cardInfo
          });
        }
        else if(this.savePayment){
          let contracts = Object.values(this.contracts);
          let contractCustomers = [];
          contracts.forEach((value) => {
            contractCustomers.push({
              contractId: value.contractId,
              customerId: value.customerId
            })
          });
          await this.savePaymentMethod({
            customerContractsDto: contractCustomers,
            paymentMethodDto: {...this.cardInfo}
          });
        }

       await this.$router.push({ path: '/confirmation' });
      } catch (error) {
        this.loading = false;
        this.setNotification({
          ...ErrorAlerts,
          message: error.response ? error.response.data.errorMessage : "A form field was not filled out"
        });
      }
    },
    onAcceptCardNumber(event) {
      const maskRef = event.detail;
      this.cardNumber = maskRef.value;
      this.$log.info(`card number accepted: ${this.cardNumber}`);
    },
    onCompleteCardNumber(event) {
      const maskRef = event.detail;
      const cardType = maskRef.masked.currentMask.cardtype;

      this.cardStyle = CardTypeDisplay[cardType];
      this.cardType = cardType;
    },
    onAcceptExpirationDate(event) {
      const maskRef = event.detail;
      this.expirationDate = maskRef.value;
      this.$log.info(`expiration date accepted: ${this.expirationDate}`);
    },
    onAcceptSecurityCode(event) {
      const maskRef = event.detail;
      this.securityCode = maskRef.value;
      this.$log.info(`security code accepted: ${this.securityCode}`)
    },
    onSelectSavedCard(data){
      if(data === "Select"){
        this.cardNumber = "";
        this.expirationDate = "";
        this.securityCode = "";
        this.cardStyle = CardTypeDisplay['Unknown'];
      } else {
        this.cardNumber = data;
        this.expirationDate = this.savedCards.find(c => c.accountNumber === data).expirationDate;
      }
    },
    onSaveCardCheckboxSelected(data){
      this.savePayment = data;
    },
    onAddAutopaySelected(data){
      this.addAutopay = data;
      this.showModal = data;
    }
  },
  directives: {
    imask: IMaskDirective,
  },
  async created() {
    if (!this.customer.isGuest) {
      let savedUnitIds = this.savedPaymentMethods.flatMap(card => card.contractUnitIds);
      this.autoPayUnits = Object.values(this.selectedContracts).map(c => c.contractUnits).flat();
      this.autoPayUnits = this.autoPayUnits.filter(unit => !savedUnitIds.includes(unit.contractUnitId));

      this.allowAutopay = this.acceptAutopay && this.autoPayUnits.length !== 0;
    }

    await this.getSavedPaymentMethods({"centralCustomerId": this.customer.centralCustomerId, "entity": this.entity})
    this.savedCards = this.savedPaymentMethods;

  }
};
</script>

<style lang="scss" scoped>
.payment-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 600px;
  grid-template-rows: 30px 70px auto auto;
  padding: 20px 36px 40px 36px;
  justify-content: center;

  &__title {
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
  }
}

.credit-card {
  width: 100%;
}

.form-container {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  grid-template-columns: 66% 33%;
  grid-template-rows: auto auto auto auto auto;
  grid-template-areas:
    "encrypted encrypted"
    "cards cards"
    "name name"
    "number number"
    "expiration security"
    "checkbox checkbox";
  justify-content: center;

  &__encrypted {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  &__field-container:nth-of-type(1) {
    grid-area: encrypted;
  }

  &__field-container:nth-of-type(2) {
    grid-area: cards;
  }

  &__field-container:nth-of-type(3) {
    grid-area: name;
  }

  &__field-container:nth-of-type(4) {
    grid-area: number;
  }

  &__field-container:nth-of-type(5) {
    grid-area: expiration;
  }

  &__field-container:nth-of-type(6) {
    grid-area: security;
  }

  &__field-container:nth-of-type(7) {
    grid-area: checkbox;
  }

  &__field-container:nth-of-type(8) {
    grid-area: submitBtn;
  }
}

.ccicon {
  height: 38px;
  position: absolute;
  right: 6px;
  top: calc(50% - 8px);
  width: 60px;
}

.total-container {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 600px;
  grid-template-rows: auto auto 70px;
  padding: 20px 36px 40px 36px;
  background-color: $lightest-grey;
  justify-content: center;

  &__button {
    position: relative;
  }

  &__total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__label {
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
  }

  &__value {
    font-size: 22px;
    font-weight: bold;
    line-height: 25px;
    color: $primary-color;
  }

  &__prepay {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 25px;
  }

  .total-container:nth-child(2) &__hide-row {
    visibility: hidden;
  }

  &--hide-row {
    grid-template-rows: auto 0 70px;
  }
}

.checkbox-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-container__unitList{
  list-style-type: none;
  padding: 0;
}

.modal-container__btn-group{
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  &-cancel {
    font-size: 16px;
    background: #eee;
    color: $black;
    padding: 0.5em 1em;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #cdcdcd;

    &:hover {
      background: $light-grey;
      border: 1px solid $medium-grey;
    }
  }

  &-confirm {
    font-size: 16px;
    background: $primary-color;
    color: $white;
    padding: 0.5em 1em;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #cdcdcd;

    &:hover {
      background: $light-grey;
      border: 1px solid $medium-grey;
      color: $black;
    }
  }
}

@media(max-width: $media-large) {
  .payment-container {
    grid-template-columns: 350px;
  }

  .total-container {
    grid-template-columns: 350px;
  }
}
</style>
