<template>
  <div class="unit-breakdown-container">
    <div class="unit-breakdown-container__title">Unit {{unit.unitNumber}}</div>
    <div class="breakdown-box">
      <div class="breakdown-box__row">
        <div class="breakdown-box__label">
          <span v-if="monthsAhead > 0">Prepay {{monthsAhead}}</span>
          <span v-else>Current</span>
          Month(s) Rent:
        </div>
        <div class="breakdown-box__value">{{ formatCurrency(paymentData.breakdownRent) }}</div>
      </div>
      <div class="breakdown-box__row">
        <div class="breakdown-box__label">Services:</div>
        <div class="breakdown-box__value">{{ formatCurrency(paymentData.breakdownFees) }}</div>
      </div>
      <div class="breakdown-box__row">
        <div class="breakdown-box__label">Tax:</div>
        <div class="breakdown-box__value">{{ formatCurrency(paymentData.breakdownTax) }}</div>
      </div>
      <div class="breakdown-box__row breakdown-box__row--bold">
        <div class="breakdown-box__label">Balance Due:</div>
        <div class="breakdown-box__value">{{ formatCurrency(paymentData.breakdownBalance) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {toCurrency} from "@/global/Helpers";
export default {
  name: "UnitBreakdown",
  props: {
    unit: Object,
    monthsAhead: Number
  },
  computed: {
    paymentData() {
      return this.unit.unitPaymentBreakdowns[this.monthsAhead];
    }
  },
  methods: {
    formatCurrency(value) {
      return toCurrency(value);
    }
  }
}
</script>

<style lang="scss" scoped>
.unit-breakdown-container {
  &__title {
    text-align: center;
    color: $dark-grey;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-top: 15px;
  }
}

.breakdown-box {
  background-color: $lightest-grey;
  padding: 8px 15px 13px 15px;
  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    &--bold {
      font-weight: bold;
      line-height: 16px;
    }
  }

  &__row:first-of-type {
    margin-top: 0;
  }

  &__label,
  &__value {
    font-size: 14px;
    line-height: 18px;
    color: $black;
  }
}
</style>
