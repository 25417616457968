<template>
  <div class="form-container">
    <div class="form-container__field-container form-container__header">
      Create Account
    </div>
    <div class="form-container__field-container form-container__sub-header">
      Verify Security Code
    </div>
    <div class="form-container__field-container form-container__verify-text" v-if="currentLogin.verificationType === 'phone'">
      Enter the verification code that we sent to {{currentLogin.phoneNumber}}. If you did not receive the text message, please resend the code.
    </div>
    <div class="form-container__field-container form-container__verify-email" v-else>
      Enter the verification code that we sent to {{currentLogin.email}}. If you did not receive the email, please check your spam folder or resend the code.
    </div>
    <div class="form-container__field-container">
      <label for="verify">Verification Code</label>
      <input id="verify" v-model="form.verificationCode" />
    </div>
    <div class="form-container__field-container">
      <button id="submitBtn" class="btn-primary" @click="submitForm" :disabled="loading">
        <font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
        <span v-if="!loading">Verify Code</span>
      </button>
    </div>
    <div class="form-container__field-container">
      <a class="form-container__forgot-password" href="#" @click="resendCode">Resend Code</a>
    </div>
    <the-footer class="form-container__field-container"/>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import Login from "@/api/Login";
import TheFooter from "@/components/SingleTime/TheFooter";
import { useNotificationStore } from '@dafcoe/vue-notification';
import { ErrorAlerts } from '@/global/Helpers';

export default {
  name: "VerifyAccount",
  components: {
    TheFooter
  },
  setup() {
    const store = useStore();
    const { setNotification } = useNotificationStore();
    const currentLogin = computed(() => store.getters['user/currentLogin']);

    return {
      setNotification,
      currentLogin
    }
  },
  data() {
    return {
      form: {
        verificationCode: ''
      },
      username: this.currentLogin.verificationType === 'phone' ?
        this.currentLogin.phoneNumber : this.currentLogin.email,
      loading: false
    }
  },
  methods: {
    resendCode() {
      this.$router.push({path: '/register'});
    },
    async submitForm() {
      try {
        this.loading = true;
        await Login.verifyCustomerAccount({ username: this.username, pin: this.form.verificationCode });
        await this.$router.push({path: '/login'});
      } catch (error) {
        this.loading = false;
        this.setNotification({
          ...ErrorAlerts,
          message: error.response.data.errorMessage
        });
        this.$log.error(error);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: 45px 35px 45px min-content 90px 45px 100px;
  grid-template-areas:
    "header"
    "subHeader"
    "emailOrPhoneMessage"
    "verificationCode"
    "verifyButton"
    "resendLink"
    "footer";
  justify-content: center;
  padding: 36px 36px 10px 36px;
  color: $black;

  &__header {
    text-align: center;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 600;
    font-size: 26px;
  }

  &__sub-header {
    text-align: center;
    line-height: 27px;
    font-weight: 500;
    font-size: 21px;
  }

  &__field-container:first-of-type {
    grid-area: header;
  }

  &__field-container:nth-of-type(2) {
    grid-area: subHeader;
  }

  &__field-container:nth-of-type(3) {
    grid-area: emailOrPhoneMessage;
  }

  &__field-container:nth-of-type(4) {
    grid-area: verificationCode;
  }

  &__field-container:nth-of-type(5) {
    grid-area: verifyButton;
  }

  &__field-container:nth-of-type(6) {
    grid-area: resendLink;
  }

  &__field-container:nth-of-type(7) {
    grid-area: footer;
  }
}
</style>
