import Axios from 'axios';
import {API_ROOT} from "@/constants/ApiRoot";

const LOCATION_ENDPOINT = `${API_ROOT}/location`;

export default {
  async getLocation(locationId) {
    return await Axios.get(`${LOCATION_ENDPOINT}/${locationId}`);
  },
  async getLocationByEntityAsync(entityId){
    return await Axios.get(`${LOCATION_ENDPOINT}/entity/${entityId}`);
  }
}
