export const getMonthsAhead = store => {
  return store.monthsAhead;
};

export const getMonthsAheadDisplay = store => {
  return store.monthsAheadDisplay;
};

export const getPaymentTotal = store => {
  return store.paymentTotal;
};

export const getPayments = store => {
  return store.payments;
};

export const getSavedPaymentMethods = store => {
  return store.savedPaymentMethods;
};

export const getCardInfo = store => {
  return store.cardInfo;
};

export const getReceipts = store => {
  return store.receipts;
};

export const getReceiptIds = store => {
  return store.receiptIds;
};

export const getReceiptNumbers = store => {
  const receipts = Object.values(store.receipts);
  return receipts.map(receipt => receipt.receiptNumber);
};

export const hasAutoPayUnits = store => {
  const cards = Object.values(store.savedPaymentMethods);
  return cards.some(card => Object.values(card.contractUnitIds).length > 0);
}
