import Axios from "axios";
import {API_ROOT} from "@/constants/ApiRoot";

const CONTRACT_ENDPOINT = `${API_ROOT}/contract`;

export default {
  async getCustomerContracts(contractDto) {
    return await Axios.get(`${CONTRACT_ENDPOINT}`, {
      params: contractDto
    });
  }
}
