export default {
  updatePayment(state, payment) {
    state.payments = {
      ...state.payments,
      [payment.contractId]: { ...payment }
    }
  },
  updateCardInfo(state, card) {
    state.cardInfo = {
      ...state.cardInfo,
      ...card
    }
  },
  updateMonthsAhead(state, monthsAhead) {
    state.monthsAhead = monthsAhead;
  },
  updateMonthsAheadDisplay(state, monthsAheadDisplay) {
    state.monthsAheadDisplay = monthsAheadDisplay;
  },
  updateTotal(state, total) {
    state.paymentTotal = total;
  },
  updateReceipts(state, receipts) {
    state.receipts = {
      ...state.receipts,
      ...receipts
    }
  },

  updateSavedPaymentMethods(state, savedPaymentMethods){
    state.savedPaymentMethods = savedPaymentMethods
  },

  updateReceiptIds(state, receiptId) {
    state.receiptIds.push(receiptId);
  },
  reset(state) {
    const s = {
      payments: {},
      cardInfo: {
        nameOnCard: '',
        creditCardNumber: '',
        cardSecurityCode: '',
        expirationDate: '',
        cardType: ''
      },
      monthsAhead: 0,
      monthsAheadDisplay: '',
      paymentTotal: 0,
      receiptIds: [],
      receipts: [],
      savedPaymentMethods: []
    }

    Object.keys(s).forEach(key => {
      state[key] = s[key]
    });
  }
}
