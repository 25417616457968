import IIdleDirective from '@/directives/IIdleDirective';


const plugin = {
  install: (Vue) => {
    Vue.component('v-idle', IIdleDirective);
  }
}

IIdleDirective.install = plugin.install;

export default IIdleDirective;
