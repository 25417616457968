<template>
  <div class="paymentSelection-container">
    <div class="paymentSelection-container__title">Select Payment Method</div>
    <div class="paymentSelection-container__button-manual">
        <router-link to="/contract">
          <button class="btn btn-primary">
            <span>Make One Time Payment</span>
          </button>
        </router-link>
    </div>
    <div class="paymentSelection-container__button-autopay">
        <router-link to="/autopay">
          <button class="btn btn-secondary">
            <span>Manage Autopay</span>
          </button>
        </router-link>
    </div>
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
.paymentSelection-container {
  display: grid;
  grid-template-columns: 500px;
  grid-template-rows: 90px 90px 90px min-content 90px 25px 100px;
  grid-template-areas:
    "title title title"
    "manual manual manual"
    "autopay autopay autopay";
  justify-content: center;
  padding: 36px 36px 10px 36px;
  color: $black;

  &__title {
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    grid-area: title;
  }

  &__button-manual {
    grid-area: manual;
    position: relative;
  }

  &__button-autopay {
    grid-area: autopay;
    position: relative;
  }

  @media(max-width: $media-large) {
    .paymentSelection-container {
      grid-template-columns: 350px;
    }
  }
}
</style>
