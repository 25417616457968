<template>
  <div class="form-container">
    <div class="form-container__field-container form-container__header">
      Password Reset
    </div>
    <div class="form-container__field-container">
      <span>A verification pin will be sent to your Email or Phone Number</span>
    </div>
    <div class="form-container__field-container">
      <label for="username">Email or Phone Number</label>
      <input id="username" maxlength="100" type="text" v-model="form.username"/>
    </div>
    <div class="form-container__field-container">
      <button id="submitBtn" class="btn-primary" @click="submitForm" :disabled="loading">
        <font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
        <span v-if="!loading">Send Pin</span>
      </button>
    </div>
    <the-footer class="form-container__field-container"/>
  </div>
</template>

<script>
import Login from '@/api/Login';
import TheFooter from "@/components/SingleTime/TheFooter";
import { useStore } from 'vuex';
import { useNotificationStore } from '@dafcoe/vue-notification';
import { ErrorAlerts } from "@/constants/ErrorAlerts";

export default {
  name: "ResetUsername",
  components: {
    TheFooter
  },
  setup() {
    const store = useStore();
    const setUsername = (username) => store.dispatch('user/setUsername', username);
    const {setNotification} = useNotificationStore();

    return {
      setUsername,
      setNotification
    }
  },
  data() {
    return {
      form: {
        username: ''
      },
      loading: false
    }
  },
  methods: {
    async submitForm() {
      try {
        this.loading = true;
        await this.setUsername(this.form.username);
        await Login.sendCustomerPasswordReset(this.form.username);
        await this.$router.push({path: '/reset/verify'});
      } catch(error) {
        this.loading = false;
        this.setNotification({
          ...ErrorAlerts,
          message: error.response.data.errorMessage
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: 45px 45px 90px 90px 100px;
  grid-template-areas:
    "header"
    "subHeader"
    "username"
    "submitBtn"
    "footer";
  justify-content: center;
  padding: 36px 36px 10px 36px;
  color: $black;

  &__header {
    text-align: center;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 600;
    font-size: 26px;
  }

  &__field-container:first-of-type {
    grid-area: header;
  }

  &__field-container:nth-of-type(2) {
    grid-area: subHeader;
  }

  &__field-container:nth-of-type(3) {
    grid-area: username;
  }

  &__field-container:nth-of-type(4) {
    grid-area: submitBtn;
  }

  &__field-container:nth-of-type(5) {
    grid-area: footer;
  }
}

@media(max-width: $media-large) {
  .form-container {
    max-width: 350px;
  }
}
</style>
