<template>
  <div class="location-container" v-if="location.entityLogoImage">
    <div class="location-container__address" :class="loadError ? 'location-container__address--no-image': ''">
      <div class="address__location-name">
        {{ location.name }}
      </div>
      <div class="address__line-one">
        {{ location.address1 }}
      </div>
      <div class="address__line-two">
        {{ `${location.city}, ${location.state} ${location.zip}` }}
      </div>
    </div>
    <div class="location-container__logo" :class="loadError ? 'location-container__logo--no-image': ''">
      <img :src="location.entityLogoImage.imageUrlMain" alt="entity logo" @error="loadError = true" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'TheHeader',
  setup() {
    const store = useStore();
    const location = computed(() => store.getters['location/getLocation']);

    return {
      location
    }
  },
  data() {
    return {
      loadError: false
    }
  },
}
</script>

<style lang="scss" scoped>
.location-container {
  background: $lightest-grey 0% 0% no-repeat padding-box;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &__logo {
    padding: 18px 221px 18px 21px;

    & img {
      height: 69px;
      width: 69px;
    }

    &--no-image {
      display: none;
    }
  }
}

.location-container__address {
  width: 100%;
  align-self: center;
  margin-left: 221px;
  text-align: left;

  &--no-image {
    margin-left: 0;
    padding: 18px 21px;
    text-align: center;
  }
}

.address__location-name {
  font-weight: bold;
  font-size: 18px;
  color: $dark-grey;
  line-height: 22px;
  letter-spacing: 0;
}

.address__line-one,
.address__line-two {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: $black;
}

@media(max-width: $media-large) {
  .location-container {
    &__logo {
      padding: 18px 21px;
    }

    &__address {
      margin-left: 21px;
    }
  }
}
</style>
