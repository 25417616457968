<template>
  <div class="progressbar-container">
    <ul class="progressbar-container__progressbar">
      <li v-for="(item, key) in steps"
          :key="key"
          :class="{active: item.step === active, completed: item.step < active}"
          @click="navigate(item.path)">
        <font-awesome-icon v-if="item.step < active" icon="check" />
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: "StepNav",
  props: {
    active: Number
  },
  setup() {
    const store = useStore();
    const steps = computed(() => store.getters['navigation/getStepNavData']);

    return {
      steps
    }
  },
  methods: {
    navigate(path) {
      this.$router.push({ path });
    }
  }
}
</script>

<style lang="scss" scoped>
.progressbar-container {
  position: relative;
  z-index: 1;

  &__progressbar {
    counter-reset: step;
    display:flex;
    justify-content: center;
    padding: 0;
  }

  &__progressbar li {
    float: left;
    width: 30%;
    position: relative;
    text-align: center;
    list-style-type: none;
  }

  &__progressbar li:before {
    content:counter(step);
    counter-increment: step;
    width: 24px;
    height: 24px;
    border: 1px solid $medium-grey;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 23px;
    font-size: 16px;
    background: white;
    color: $medium-grey;
    text-align: center;
    cursor: pointer;
  }

  &__progressbar li:after {
    content: '';
    position: absolute;
    width: 99%;
    height: 2px;
    background: $light-grey;
    top: 12px;
    left: -50%;
    z-index: -1;
  }

  &__progressbar li.active:before {
    border-color: $primary-color;
    background: white;
    color: $primary-color;
    font-weight: bold;
  }

  &__progressbar li.active:after {
    background: $primary-color;
  }

  &__progressbar li.completed:before {
    border-color: $primary-color;
    background: $primary-color;
    color: white;
    content: ""
  }

  &__progressbar li.completed:after {
    background: $primary-color;
  }

  &__progressbar li:first-child:after {
    content: none;
  }
}

.fa-check {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  cursor: pointer;
}
</style>
