<template>
  <div class="autopay-container">

    <div class="contract-container">
      <div class="contract-container__header">

        <div class="nav-container">
          <router-link to="/dashboard">
            <button class="nav-container__back-button">
              <font-awesome-icon :icon="['fas', 'arrow-left']" />
            </button>
          </router-link>
        </div>

        <h1 class="contract-container__title">Available Contracts</h1>

      <span></span>
      </div>
      <div class="contract-container__contractList">
        <div class="contract-container__spinner" v-if="loadingContracts">
          <font-awesome-icon v-if="loadingContracts" icon="spinner" class="fa-spin" />
        </div>
        <table v-else class="contract-container__table">
          <thead>
            <tr>
              <th>Contract Number</th>
              <th>Unit Number</th>
              <th>Room Rate</th>
              <th>Due Date</th>
              <th class="align-text-right col-autopay">Autopay Card</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tr v-for="unit in getContractUnitList()" :key="unit.unitNumber">
            <td data-label="Contract Number">{{ `${this.entity}-${unit.contractNumber}`}}</td>
            <td data-label="Unit Number">{{ unit.unitNumber }}</td>
            <td data-label="Room Rate">{{ convertCurrency(unit.roomRate) }}</td>
            <td data-label="Due Date">{{ convertDate(unit.dueDate) }}</td>
            <td data-label="Autopay Card" class="align-text-right">{{unit.cardNumber}}</td>
            <td data-label="Actions">
              <div class="contract-container__table-btn-group" v-if="hasAutoPay(unit.contractUnitId)">
                <button class="btn btn-widget" v-if="hasAutoPay(unit.contractUnitId)" @click="showCard(unit.contractUnitId, unit.contractId, true)">
                  <font-awesome-icon :icon="['fas', 'edit']" /> Edit Card
                </button>
                <button class="btn btn-widget" @click="onRemoveCareClick(unit.contractUnitId)">
                  <font-awesome-icon icon="trash" /> Remove Card
                </button>
              </div>
              <div class="contract-container__table-btn-group" v-else>
                <button class="btn btn-widget" @click="showCard(unit.contractUnitId, unit.contractId, false)">
                  <font-awesome-icon :icon="['fas', 'plus']" /> Add A Card
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="card-container" v-show="showCardForm" ref="ccWrapper">
      <h3 class="card-container__editWarning" v-if="isEdit">Updating card information will update all units that are currently assigned to this card.</h3>
      <CardForm ref="cardFormRef"/>
      <button class="btn card-container__save-button btn-primary" @click="submitCard()" :disabled="loading">
        <font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
        <font-awesome-icon v-if="!loading" :icon="['fas', 'save']" /> Save Payment
      </button>
    </div>

    <modal :show-modal="showModal" @close-modal="showModal = false">
      <template v-slot:header>
        <h3>Remove Card</h3>
      </template>
      <template v-slot:body>
        <p>Are you sure you want to remove autopay from this unit?</p>
      </template>
      <template v-slot:footer>
        <div class="modal-container__btn-group">
          <button class="btn modal-container__btn-group-cancel" @click="selectedContractUnit = null; showModal = false">Cancel</button>
          <button class="btn modal-container__btn-group-confirm" @click="onConfirmRemoveCard(this.selectedContractUnit); showModal = false">Confirm</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {computed} from 'vue';
import {useStore} from 'vuex';
import CardForm from "@/components/Card/CardForm.vue";
import { toCurrency } from "@/global/Helpers";
import {ErrorAlerts} from "@/constants/ErrorAlerts";
import Modal from "@/components/Modal/Modal.vue";


export default {
  name: "AutoPay",
  components: {Modal, CardForm},
  setup() {
    const store = useStore();
    const contracts = computed(() => store.getters['contracts/getContracts']);
    const customer = computed(()=> store.getters['user/currentCustomer']);
    const cardInfo = computed(() => store.getters['payment/getCardInfo']);
    const entity = computed(() => store.getters['location/getEntity']);
    const savedPaymentMethods = computed(() => store.getters['payment/getSavedPaymentMethods']);
    const fetchContracts = (contractDto) => store.dispatch('contracts/fetchContractsForCustomer', contractDto);
    const getSavedPaymentMethods = (getPaymentMethodsDto) => store.dispatch('payment/getPaymentMethods', getPaymentMethodsDto);
    const removeAutoPay = (contractUnitId) => store.dispatch('payment/removeAutoPay', contractUnitId);
    const updateAutoPay = (autopayDto) => store.dispatch('payment/updateAutoPay', autopayDto);
    const savePaymentMethod = (savePaymentMethodsDto) => store.dispatch('payment/savePaymentMethod', savePaymentMethodsDto);

    return {
      contracts,
      customer,
      cardInfo,
      entity,
      savedPaymentMethods,
      fetchContracts,
      getSavedPaymentMethods,
      removeAutoPay,
      updateAutoPay,
      savePaymentMethod
    };
  },
  data() {
    return {
      showCardForm: false,
      savedCards: this.savedPaymentMethods,
      selectedContractUnit: null,
      selectedContract: null,
      selectedSavedCard: null,
      isEdit: false,
      loading: false,
      loadingContracts: false,
      selectedRemoveUnit: null,
      showModal: false,
    };
  },
  methods: {
    convertDate(dateString) {
      const date = new Date(dateString);
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();

      return `${month}/${day}/${year}`;
    },
    convertCurrency(rate) {
      return toCurrency(rate)
    },
    getContractUnitList() {
      let cards = this.savedCards;
      let contractUnitList = [];
      contractUnitList.push(Object.values(this.contracts).flatMap(contract =>
        contract.contractUnits.map(unit => ({
          contractNumber: contract.contractNumber,
          unitNumber: unit.unitNumber,
          roomRate: unit.roomRate,
          dueDate: unit.dueDate,
          contractUnitId: unit.contractUnitId,
          contractId: contract.contractId,
          cardNumber: cards.find(card => Object.values(card.contractUnitIds).includes(unit.contractUnitId))?.accountNumber?.slice(-4) ?? "No Card On File"
        }))
      ));

      return contractUnitList.flat();
    },
    hasAutoPay(unitID) {
      return this.savedPaymentMethods.some(card => Object.values(card.contractUnitIds).includes(unitID));
    },
    showCard(unitId, contractId, isEdit) {
      this.selectedContractUnit = unitId;
      this.selectedContract = contractId;

      this.isEdit = isEdit;
      this.showCardForm = true;

      this.selectedSavedCard = isEdit
        ? this.savedCards.findIndex(card => Object.values(card.contractUnitIds).includes(unitId))
        : -1;

      let cardNumber = this.selectedSavedCard !== -1
        ? this.savedCards[this.selectedSavedCard]?.accountNumber
        : undefined;

      if(cardNumber !== undefined) {
        let cardForm = this.$refs.cardFormRef;

        if (cardForm !== undefined) {
          cardForm.onSelectSavedCard(cardNumber);
        }
      }else{
        let cardForm = this.$refs.cardFormRef;

        if (cardForm !== undefined) {
          cardForm.clearSelectedCard();
        }
      }

      this.$nextTick(() => {
        const element = this.$refs.ccWrapper;
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    },
    onRemoveCareClick(contractUnitId) {
      this.selectedContractUnit = contractUnitId;
      this.showModal = true;
    },
    onConfirmRemoveCard() {
      this.removeAutoPay(this.selectedContractUnit);
      this.showModal = false;
      window.location.reload();
    },
    async submitCard() {
      this.loading = true;
      let cardForm = this.$refs.cardFormRef;

      if (cardForm !== undefined) {
        try{
          if (cardForm.submitForm()){
            let contractUnitIds = [this.selectedContractUnit];

            if(this.isEdit) {
              let card = this.savedCards[this.selectedSavedCard];
              contractUnitIds = card.contractUnitIds;
            }

            await this.updateAutoPay({
              contractUnitIds: contractUnitIds,
              ...this.cardInfo
            });

            this.loading = false;
            window.location.reload();
          }else{
            this.loading = false;
          }
        }catch (error) {
          this.loading = false;
          this.setNotification({
            ...ErrorAlerts,
            message: error.response.data.errorMessage
          });
        }
      }
    }
  },
  async mounted() {
    if (Object.values(this.contracts).length === 0) {
      try {
        this.loadingContracts = true;
        await this.fetchContracts({ customerId: this.customer.customerId, centralCustomerId: this.customer.centralCustomerId,
          entityId: this.entity });
        this.loadingContracts = false;
      } catch(error) {
        this.setNotification({
          ...ErrorAlerts,
          message: error.response.data.errorMessage
        });
      }
    }
  },
  async created() {
    await this.getSavedPaymentMethods({"centralCustomerId": this.customer.centralCustomerId, "entity": this.entity})
    this.savedCards = this.savedPaymentMethods;
  }
}
</script>

<style scoped lang="scss">
  .autopay-container,
  .contract-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  .autopay-container {
    margin-bottom: 2rem;
  }

  .contract-container {
    &__spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    &__title {
      font-size: 26px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }

    &__table-btn-group {
      display: flex;
      gap: 12px;
      padding: 4px 0;

      .btn-widget {
        font-size: 16px;
        background: #eee;
        color: #204d6c;
        padding: 0.5em 1em;
        border-radius: 2px;
        cursor: pointer;
        border: 1px solid #cdcdcd;

        &:hover {
          background: #204d6c;
          color: white;
          border: 1px solid #204d6c;
        }
      }
    }
  }

  .nav-container {
    &__back-button {
      font-size: 24px;
      padding: 8px;
      background: none;
      color: $primary-color;
      border: none;
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }

  .card-container {
    &__editWarning {
      color: $error;
      text-align: center;
    }
    &__save-button {
      position: unset;
      width: 275px;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  .modal-container__btn-group{
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    &-cancel {
      font-size: 16px;
      background: #eee;
      color: $black;
      padding: 0.5em 1em;
      border-radius: 2px;
      cursor: pointer;
      border: 1px solid #cdcdcd;

      &:hover {
        background: $light-grey;
        border: 1px solid $medium-grey;
      }
    }

    &-confirm {
      font-size: 16px;
      background: $primary-color;
      color: $white;
      padding: 0.5em 1em;
      border-radius: 2px;
      cursor: pointer;
      border: 1px solid #cdcdcd;

      &:hover {
        background: $light-grey;
        border: 1px solid $medium-grey;
        color: $black;
      }
    }
  }

  /* Text Align */
  .align-text-right {
    text-align: right;
  }

</style>


<style scoped lang="scss">
  // Custom tables
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
  }

  th,
  td {
    padding: 0.25em 0.75em;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    padding: 0.75em;
  }

  tr {
    margin-bottom: 10px;

    &:nth-child(even) {
      background-color: #f9f9f9;
    }

    &:nth-child(odd) {
      background-color: #fff;
    }
  }

  /* Autopay table specifics*/
  .col-autopay {
    width: 150px;
  }

  @media (max-width: 1440px) {
    table {
      font-size: 0.875rem;
    }
  }

  @media screen and (max-width: 980px) {
    //Shrink tables (Can be improved later)

    table {
      font-size: 1rem;

      caption {
        font-size: 1rem;
      }

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
      }

      td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 1rem;
        text-align: right;
        padding: 8px;

        &::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .contract-container {
      &__table-btn-group {
        justify-content: flex-end;
      }

      .btn-widget {
        font-size: 14px;
      }
    }
  }

</style>

<style>
  @media(max-width: 1440px) {
      #app:has(.autopay-container) {
         width: 100%;
         margin: 115px 2rem  0 2rem;
      }
  }
</style>
