import Axios from "axios";
import {API_ROOT} from "@/constants/ApiRoot";

const LOGIN_ENDPOINT = `${API_ROOT}/login`;

// we only need the timeout here since the ccp services suck
Axios.defaults.timeout = 1000 * 60 * 5;

export default {
  async loginCustomer(loginDto) {
    return await Axios.post(`${LOGIN_ENDPOINT}`, loginDto);
  },
  async logoutCustomer(username) {
    return await Axios.post(`${LOGIN_ENDPOINT}/${username}`);
  },

  async createCustomerAccount(loginDto) {
    return await Axios.post(`${LOGIN_ENDPOINT}/register`, loginDto);
  },
  async verifyCustomerAccount(verificationDto) {
    return await Axios.post(`${LOGIN_ENDPOINT}/verify`, verificationDto);
  },

  async sendCustomerPasswordReset(username) {
    return await Axios.post(`${LOGIN_ENDPOINT}/${username}/reset`);
  },
  async resetCustomerPassword(passwordResetDto) {
    return await Axios.post(`${LOGIN_ENDPOINT}/reset`, passwordResetDto);
  },
}
