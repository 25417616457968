<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="custom-select__selected" :class="{ open: open }" @click.self="open = !open">
      {{ selected }}
      <font-awesome-icon v-if="itemSelected" icon="times" @click.stop="clearSelect"/>
      <font-awesome-icon icon="angle-down" class="fa-lg" :class="{ 'fa-rotate-180': open }" @click="open = !open"/>
    </div>
    <div class="custom-select__items" :class="{ 'custom-select__items--hide': !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        :disabled="disabled(option)"
        class="custom-select__option"
        :class="{ 'custom-select__option--disabled': disabled(option) }"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        ">
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Multiselect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    // allow parent to define disabled logic
    disabled: {
      type: Function,
      required: false,
      default: () => false
    }
  },
  computed: {
    itemSelected() {
      return this.default !== this.selected;
    }
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
          ? this.options[0]
          : null,
      open: false,
    };
  },
  methods: {
    clearSelect() {
      this.selected = this.default;
      this.open = false;
      this.$emit("input", this.selected);
    }
  },
  mounted() {
    this.$emit("input", this.selected);
  },
}
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 50px;
  line-height: 50px;

  &__selected {
    background-color: $white;
    border-radius: 0;
    border: 1px solid $light-grey;
    color: $black;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
  }

  &__items {
    color: $black;
    border-radius: 0;
    overflow: hidden;
    border-right: 1px solid $light-grey;
    border-left: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    position: absolute;
    background-color: $white;
    left: 0;
    right: 0;
    z-index: 1;

    &--hide {
      display: none;
    }
  }

  &__option {
    color: $black;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: $lightest-grey;
    }

    &--disabled {
      pointer-events: none;
      color: $light-grey;
    }
  }
}

.fa-angle-down {
  position: absolute;
  content: "";
  top: 16px;
  right: 1em;
  color: $black;
  transition: .4s;
}

.fa-times {
  position: absolute;
  content: "";
  top: 19px;
  right: 3em;
}
</style>
