export default {
  addUnitToSelectedContract(state, contractInfo) {
    const { contractNumber, contractUnitNumber } = contractInfo;

    if (Object.keys(state.selectedContractUnits).includes(contractNumber.toString())) {
      let units = [...state.selectedContractUnits[contractNumber]];
      units.push(contractUnitNumber);
      units = [...new Set(units)];
      state.selectedContractUnits[contractNumber] = units;
    } else {
      state.selectedContractUnits[contractNumber] = [contractUnitNumber];
    }
  },
  removeUnitFromSelectedContract(state, contractInfo) {
    const { contractNumber, contractUnitNumber } = contractInfo;
    state.selectedContractUnits[contractNumber] =
      state.selectedContractUnits[contractNumber].filter(unit => unit !== contractUnitNumber);
    if(state.selectedContractUnits[contractNumber].length === 0) {
      delete state.selectedContractUnits[contractNumber];
    }
  },
  setContracts(state, contracts) {
    state.contracts = {
      ...state.contracts,
      ...contracts
    }
  },
  reset(state) {
    const s = {
      selectedContractUnits: {},
      contracts: {},
      contractsForPayment: {}
    }

    Object.keys(s).forEach(key => {
      state[key] = s[key]
    });
  }
}
