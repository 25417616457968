<template>
  <label class="check-container">
    <input type="checkbox"
           :checked="checked"
           @click="$emit('checkClicked', !checked);">
    <span class="check-container__checkmark"></span>
    <label class="check-container__label">{{label}}</label>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    checked: Boolean,
    label: String
  },
}
</script>

<style lang="scss" scoped>
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 19px;
  cursor: pointer;
  font-size: 22px;
  line-height: 26px;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background-color: $white;
    border: 2px solid $light-grey;
  }

  & input:checked ~ &__checkmark {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input:checked ~ &__checkmark:after {
    display: block;
  }

  &__label{
    vertical-align: middle;
  }

  &__checkmark:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}
</style>
