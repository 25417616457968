<template>
  <div>
    <div class="form-container" v-show="!verification">
      <div class="form-container__field-container form-container__header">
        Create Account
      </div>
      <div class="form-container__field-container">
        <label for="phoneNumber">Phone Number</label>
        <input
          id="phoneNumber"
          maxlength="15"
          type="text"
          :value="form.phoneNumber"
          @accept="event => form.phoneNumber = event.detail.value"
          v-imask="phoneNumberMask"
        />
      </div>
      <div class="form-container__field-container">
        <label for="email">Email Address</label>
        <div v-for="(error, index) of validator.form.email.$errors" :key="index">
          <strong>{{ error.$message }}</strong>
        </div>
        <input id="email" v-model="validator.form.email.$model" />
      </div>
      <div class="form-container__field-container">
        <label for="password">Password</label>
        <div v-for="(error, index) of validator.form.password.$errors" :key="index">
          <strong>{{ error.$message }}</strong>
        </div>
        <input id="password" type="password" v-model="validator.form.password.$model"/>
      </div>
      <div class="form-container__field-container">
        <label for="confirmPassword">Confirm Password</label>
        <div v-for="(error, index) of validator.confirmPassword.$errors" :key="index">
          <strong>{{ error.$message }}</strong>
        </div>
        <input id="confirmPassword" type="password" v-model="confirmPassword" />
        <i class="fa fa-info-circle"></i>
        <div>
          <font-awesome-icon icon="info-circle"/>
          Password must be at least 8 characters, contain a number, a lowercase, and an uppercase character.
        </div>
      </div>
      <div class="form-container__field-container">
        <button id="validationBtn" class="btn-primary" @click="checkValidation" :disabled="loading">
          <font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
          <span v-if="!loading">Continue</span>
        </button>
      </div>
    </div>

    <div class="verification-container" v-show="verification">
      <div class="verification__field-container verification-container__header">
        Create Account
      </div>
      <div class="verification__field-container">
        We will send a verification code to the option you select:
      </div>
      <div class="verification-container__field-container">
        <input id="phoneNumberRadio" type="radio" value="phone" v-model="form.verificationType" />
        <label for="phoneNumberRadio"><b>Phone Number</b>: {{form.phoneNumber}}</label>
      </div>
      <div class="verification-container__field-container">
        <input id="emailRadio" type="radio" value="email" v-model="form.verificationType" />
        <label for="emailRadio"><b>Email Address</b>: {{form.email}}</label>
      </div>
      <div class="verification-container__field-container">
        <button id="verificationBtn"  class="btn-primary" @click="submitForm" :disabled="loading">
          <font-awesome-icon v-if="loading" icon="spinner" class="fa-spin" />
          <span v-if="!loading">Verify Account</span>
        </button>
      </div>
    </div>
    <the-footer class="form-container__field-container"/>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import IMaskDirective from '@/directives/IMaskDirective';
import TheFooter from "@/components/SingleTime/TheFooter";
import { required, minLength, helpers, email, sameAs } from '@vuelidate/validators'
import { useStore } from "vuex";
import { computed } from "vue";
import { useNotificationStore } from '@dafcoe/vue-notification';
import { ErrorAlerts } from '@/global/Helpers';

const hasLowerCase = helpers.regex(/[a-z]/);
const hasUpperCase = helpers.regex(/[A-Z]/);
const hasNumber = helpers.regex(/\d/);

export default {
  name: "CreateAccount",
  components: {
    TheFooter
  },
  directives: {
    imask: IMaskDirective,
  },
  setup () {
    const store = useStore();
    const { setNotification } = useNotificationStore();
    const currentLogin = computed(() => store.getters['user/currentLogin']);
    const setLogin = (loginDto) => store.dispatch('user/setLoginInfo', loginDto);
    const createCustomerAccount = (loginDto) => store.dispatch('user/createCustomerAccount', loginDto);
    return {
      currentLogin,
      setNotification,
      setLogin,
      createCustomerAccount,
      validator: useVuelidate({ $lazy: true })
    }
  },
  data() {
    return {
      form: { ...this.currentLogin },
      confirmPassword: '',
      phoneNumberMask: {
        mask: "(000) 000-0000"
      },
      verification: false,
      loading: false
    }
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Not a Valid Email Address", email)
        },
        password: {
          required: helpers.withMessage("Password is required", required),
          minLength: helpers.withMessage("Password must have more than 8 characters", minLength(8)),
          hasLowerCase: helpers.withMessage("Password must have a lowercase letter", hasLowerCase),
          hasUpperCase: helpers.withMessage("Password must have an uppercase letter", hasUpperCase),
          hasNumber: helpers.withMessage("Password must have a number", hasNumber)
        },
      },
      confirmPassword: {
        required: helpers.withMessage("Password Confirmation is required", required),
        sameAs: helpers.withMessage("Passwords must match", sameAs(this.form.password))
      }
    }
  },
  methods: {
    async submitForm() {
      try {
        this.loading = true;
        this.setLogin(this.form);
        await this.createCustomerAccount(this.form);
        await this.$router.push({ path: '/verification' });
      } catch (error) {
        this.setNotification({
          ...ErrorAlerts,
          message: error.response.data.errorMessage
        });
        this.$log.error(error)
        this.loading = false;
      }
    },
    async checkValidation() {
      this.loading = true;
      await this.validator.$validate();
      if(this.validator.$errors.length === 0){
        this.verification = true;
      }
      this.loading = false;
    },
  }
}
</script>

<style scoped lang="scss">
.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: 45px min-content min-content min-content 145px 60px 100px;
  grid-template-areas:
    "header"
    "phoneNumber"
    "email"
    "password"
    "confirmPassword"
    "registerButton"
    "footer";
  justify-content: center;
  padding: 36px 36px 10px 36px;
  color: $black;

  &__header {
    text-align: center;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 600;
    font-size: 26px;
  }

  &__field-container:first-of-type {
    grid-area: header;
  }

  &__field-container:nth-of-type(2) {
    grid-area: phoneNumber;
  }

  &__field-container:nth-of-type(3) {
    grid-area: email;
  }

  &__field-container:nth-of-type(4) {
    grid-area: password;
  }

  &__field-container:nth-of-type(5) {
    grid-area: confirmPassword;
  }

  &__field-container:nth-of-type(6) {
    grid-area: registerButton;
  }

  &__field-container:nth-of-type(7) {
    grid-area: footer;
  }
}

.verification-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: 45px 45px 30px 30px 90px;
  grid-template-areas: "header" "optionMessage" "phoneNumber" "email" "verificationButton";
  justify-content: center;
  padding: 36px 36px 10px 36px;
  color: $black;

  &__header {
    text-align: center;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 600;
    font-size: 26px;
  }

  &__field-container:first-of-type {
    grid-area: header;
  }

  &__field-container:first-of-type(2) {
    grid-area: optionMessage;
  }

  &__field-container:nth-of-type(3) {
    grid-area: phoneNumber;
  }

  &__field-container:nth-of-type(4) {
    grid-area: email;
  }

  &__field-container:nth-of-type(5) {
    position: relative;
    grid-area: verificationButton;
  }

  input {
    width: auto;
  }
}

@media(max-width: $media-large) {
  .form-container {
    max-width: 350px;
  }
  .verification-container{
    max-width: 350px;
  }
}
</style>
